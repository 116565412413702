import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";

import Header from "./components/Header";
import Footer from "./components/Footer";
import Whatsapp from "./components/whatsappConnect";

import Home from "./pages/home";
import Solutions from "./pages/solutions";
import AboutUs from "./pages/about";
import Portfolio from "./pages/portfolio";
import Carrers from "./pages/career";
import Description from "./pages/description";
import ContactUs from "./pages/contact";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


export default function App() {
  return (
    <BrowserRouter>
      <div className="global">

        <Header />
        <ToastContainer 
        position={"top-right"}
          autoClose={2500}
          hideProgressBar={true}
          closeOnClick={true}
          pauseOnHover={true}
          draggable={true}
          progress={undefined} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/solutions" element={<Solutions />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/careers" element={<Carrers />} />
          <Route path="/careers/open-position" element={<Description />} />
          <Route path="/contact-us" element={""} />
        </Routes>
        <Whatsapp />
        <ContactUs />
       
        <Footer />
      </div>
    </BrowserRouter>
  );
}
