import React, { useEffect } from "react";
import "./contact.css";
import { BsTelephone } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import { CiLocationOn } from "react-icons/ci";
import ContactForm from "../components/Common/ContactForm";
import { useLocation } from 'react-router-dom';


const Contact = () => {
  const location = useLocation();
  console.log(location.pathname)
  const path = location.pathname
  return (
    <div>
      <div className="bg-contact">
      <div className="container d-flex flex-wrap  cntc">
        <div className="col-md-7 px-xl-5 px-md-2 my-md-5 mb-3 mt-5">
          <div className="bg-back-contact">
            <div className="text-center text-white top-contact">
              <h1>Connect with Us</h1>
              <p className="px-md-5 px-3">
                Connect with us to unlock endless possibilities for your
                business growth and witness our commitment to your success
                firsthand.
              </p>
            </div>
            <div className="text-white px-md-5 px-sm-3 pb-lg-5 p-4">
              <div className="contact-svg pb-3 d-flex flex-wrap">
                <div>
                  <BsTelephone className="me-3 " />
                </div>
                <div>
                  <a
                    href="tel:8805500055"
                    className="text-decoration-none text-white"
                  >
                    +91 9552238055
                  </a>
                </div>
              </div>
              <div className="contact-svg pb-3 d-flex mailFold flex-wrap">
                <div>
                  <AiOutlineMail className="me-3" />
                </div>
                <div>
                  <a
                    href="mailto:hello@datartsolutions.in"
                    className="text-decoration-none text-white"
                  >
                    hello@datartinfotech.com
                  </a>
                </div>
              </div>
              <div className="contact-svg pb-md-5 d-flex ">
                <div>
                  <CiLocationOn className="me-3 " />
                </div>
                <p>203, Pentagon 2, Magarpatta, Hadapsar-411028</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-5 px-xl-5 px-md-2 my-md-5 my-3">
          <div className="bg-back-contact">
            <ContactForm />
          </div>
        </div>

        {/* Map  */}
        <div className="col-md-10 col-12 mx-auto bg-back-contact">
          {path === "/contact-us" ? (
            <div className=" p-lg-4 p-md-4 p-3">
              <h5 className="text-white py-3 fw-light">Visit us at -</h5>
              <div
                className="mx-auto pb-5 mapDiv rounded"
                style={{
                  textDecoration: "none",
                  overflow: "hidden",
                  maxWidth: "100%",
                  width: "100%",
                }}
              >
                <div
                  id="google-maps-canvas"
                  style={{ height: "100%", width: "100%", maxWidth: "100%" }}
                >
                  <iframe
                    title="Map"
                    style={{ height: "100%", width: "100%", border: 0 }}
                    src="https://www.google.com/maps/embed/v1/place?q=Datart+Solutions,+Pentagon,+Magarpatta,+Hadapsar,+Pune,+Maharashtra,+India&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
                  />
                </div>
                {/* <a className="google-map-code-enabler" href="https://www.bootstrapskins.com/themes" id="make-map-data">premium bootstrap themes</a> */}
                <style
                  dangerouslySetInnerHTML={{
                    __html:
                      "#google-maps-canvas img.text-marker{max-width:none!important;background:none!important;}img{max-width:none}",
                  }}
                />
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
    </div>
  )
}

export default Contact
