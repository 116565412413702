import React from "react";
import "./Solutions.css";
import { SolutionItemArray } from "./SolutionItems";

export default function Solutions({ solution }) {
  let width = window.innerWidth;
  let number;
  if (width > 480) {
    number = 6;
  } else {
    number = 4;
  }

  return (
    <>
      <div className="container d-flex flex-wrap solution-card ">
        {solution
          ? SolutionItemArray.map((data, i) => {
              return (
                <>
                  {i < number ? (
                    <div
                      className="col-lg-4 col-6 mx-md-0 ps-lg-5 py-3 "
                      key={i}
                    >
                      <div className="img">
                        <img
                          loading="lazy"
                          src={data.img}
                          alt="Img"
                          className="1. captcha is not cleared after submitting form (captcha validation)"
                          width="10%"
                        />
                      </div>
                      <h6 className="text-uppercase pt-2">{data.title}</h6>
                      <p className="secondary-light-color pe-md-4 pe-3  font-small">
                        {data.para}
                      </p>
                      <div></div>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              );
            })
          : SolutionItemArray.map((data, i) => {
              return i % 2 === 0 ? (
                <div className="col-lg-4 col-6 mx-md-0 ps-lg-5 py-3" key={i}>
                  <div className="img">
                    <img
                      loading="lazy"
                      src={data.img}
                      alt="Img"
                      className="img-fluid"
                      width="10%"
                    />
                  </div>
                  <h6 className="text-uppercase pt-2">{data.title}</h6>
                  <p className="secondary-light-color pe-md-4 pe-3 font-small">
                    {data.para}
                  </p>
                </div>
              ) : (
                <div className="col-lg-4 col-6 mx-md-0 ps-lg-5 py-3" key={i}>
                  <div className="img">
                    <img
                      loading="lazy"
                      src={data.img}
                      alt="Img"
                      className="img-fluid"
                      width="10%"
                    />
                  </div>
                  <h6 className="text-uppercase pt-2">{data.title}</h6>
                  <p className="secondary-light-color pe-md-4 font-small">{data.para}</p>
                </div>
              );
            })}
      </div>
    </>
  );
}
