import React, { useState } from "react";
import { toast } from "react-toastify";
import "./ContactForm.css";
import { IoIosRefresh } from "react-icons/io";

// import ReCAPTCHA from "react-google-recaptcha";

export default function ContactForm() {
  // Captcha
  const randomString = Math.random().toString(36).slice(6);
  const [captcha, setCaptcha] = useState(randomString);
  const [inputText, setInputText] = useState();
  const [captchaValid, setCaptchaValid] = useState(true);

  const refreshString = () => {
    setCaptcha(Math.random().toString(36).slice(6));
  };

  // const [formData, setformData] = useState({})

  // const handleChange = (event) => {

  //     const { name, value } = event.target;
  //     let nameValue = name;
  //     let valueValue = value;
  //     setformData({ ...formData, [nameValue]: valueValue });

  //     if (nameValue === 'fullName') {
  //         // Validate 'name' field (e.g., it should not be empty)
  //         const errorElement = document.getElementById('nameError');
  //         errorElement.innerHTML = valueValue.trim() === '' ? 'Name is required' : '';
  //     } else if (nameValue === 'emailId') {
  //         // Validate 'email' field (e.g., it should be a valid email address)
  //         const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //         const errorElement = document.getElementById('emailError');
  //         errorElement.innerHTML = !emailPattern.test(valueValue) ? 'Invalid email address' : '';
  //     } else if (nameValue === 'contactNo') {
  //         // Validate 'contactNo' field (e.g., it should be a 10-digit number)
  //         const contactNoPattern = /^\d{10}$/;
  //         const errorElement = document.getElementById('contactNoError');
  //         errorElement.innerHTML = !contactNoPattern.test(valueValue) ? 'Invalid contact number (10 digits required)' : '';
  //     } else if (nameValue === 'message') {
  //         // Validate 'message' field (e.g., it should not be empty)
  //         const errorElement = document.getElementById('messageError');
  //         errorElement.innerHTML = valueValue.trim() === '' ? 'Message is required' : '';
  //     }
  // }

  // const submitForm = async () => {

  //     const config = {
  //         SecureToken : process.env.REACT_APP_SECURITY_TOKEN,
  //         // To : 'preetamchinde@datartsolutions.in',
  //         To : 'yashtatiya@datartsolutions.in',
  //         From : formData.emailId,
  //         Subject : `New enquiry received form ${formData.fullName}`,
  //         Body : `
  //             Full Name : ${formData.fullName} <br />
  //             Email Id : ${formData.emailId} <br />
  //             Phone Number : ${formData.contactNo} <br />
  //             Message : ${formData.message}
  //         `
  //     }

  //     if(window.Email){
  //         await window.Email.send(config)
  //         .then(res => {
  //             document.getElementById("contactForm").reset();
  //             setCaptcha(Math.random().toString(36).slice(6));
  //             setInputText('');
  //             toast.success('Your application sent successfully', {
  //                 position: "top-right", // Toast position
  //                 autoClose: 3000, // Duration in milliseconds
  //                 hideProgressBar: false, // Whether to display a progress bar
  //                 closeOnClick: true, // Close toast on click
  //                 pauseOnHover: true, // Pause the autoClose timer on hover
  //                 draggable: true, // Enable dragging to dismiss the toast
  //                 progress: undefined // Custom progress bar component
  //             })
  //         })
  //         .catch(err => {
  //             toast.error('Opps! Something went wrong', {
  //                 position: "top-right", // Toast position
  //                 autoClose: 3000, // Duration in milliseconds
  //                 hideProgressBar: false, // Whether to display a progress bar
  //                 closeOnClick: true, // Close toast on click
  //                 pauseOnHover: true, // Pause the autoClose timer on hover
  //                 draggable: true, // Enable dragging to dismiss the toast
  //                 progress: undefined // Custom progress bar component
  //             })
  //         })
  //     }
  // }
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    contactNumber: "",
    message: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const validateForm = () => {
    const errors = {};
    let isValid = true;

    if (!formData.fullName.trim()) {
      errors.fullName = "Full Name is required";
      isValid = false;
    }

    if (!formData.email.trim()) {
      errors.email = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email is invalid";
      isValid = false;
    }

    if (!formData.contactNumber.trim()) {
      errors.contactNumber = "Contact Number is required";
      isValid = false;
    } else if (!/^\d{10}$/.test(formData.contactNumber)) {
      errors.contactNumber = "Contact Number is invalid";
      isValid = false;
    }

    if (!formData.message.trim()) {
      errors.message = "Message is required";
      isValid = false;
    }
    if (inputText !== captcha) {
      errors.captcha = "Captcha is required";
      isValid = false;
    }

    return { isValid, errors };
  };

  // if (inputText !== captcha) {
  //    errors.captcha = "Captcha is required";
  // //    set= false;
  //  }

  console.log("formadata", formData);
  console.log("error", errors);

  const submitForm = (e) => {
    e.preventDefault();
    const { isValid, errors } = validateForm();

    if (isValid) {
      const config = {
        // SecureToken: process.env.REACT_APP_SECURITY_TOKEN,

        // To: "yashbaldota@datartinfotech.com",
        // From: formData.email,
        Host: "smtp.elasticemail.com",
        Username: "yashtatiya@datartinfotech.com",
        Password: "982BFD409389CF7604BF8757A0345C4FEE19",
        To: "yashtatiya@datartinfotech.com",
        From: "yashtatiya@datartinfotech.com",
        Subject: `New enquiry received form ${formData.fullName}`,
        Body: `
          Full Name : ${formData.fullName} <br />
          Email Id : ${formData.email} <br />
          Phone Number : ${formData.contactNumber} <br />
          Message : ${formData.message}
        `,
      };
      console.log("config", config);

      if (window.Email) {
        window.Email.send(config)
          .then(() => {
            toast.success(
              "Thank You for reaching out to us. We'll get back to you shortly."
            );
            window.scrollTo(0, 0);
            setFormData({
              fullName: "",
              email: "",
              contactNumber: "",
              message: "",
              captcha: "",
            });
            setInputText("");
            // setCaptcha(null);
            setCaptchaValid("");
            setErrors({ ...errors, captcha: "" });
          })
          .catch((err) => {
            toast.error("Opps! Something went wrong.");
            console.error(err);
          });
      }
    } else {
      setErrors(errors);
      console.log("Form validation failed");
    }
  };

  const checkCaptcha = (e) => {
    const value = e.target.value;
    setInputText(value);
    if (value === captcha) {
      setCaptchaValid(false);
      errors.captcha = "";
    } else {
      setCaptchaValid(true);
      errors.captcha = "Captcha is required";
    }
    //         if (inputText !== captcha) {
    //       errors.captcha = "Captcha is required";
    //  } else{
    //     errors.captcha = "Captcha is required";
    //  }
  };

  return (
    <form
      onSubmit={(e) => {
        submitForm(e);
        e.preventDefault();
      }}
      id="contactForm"
      className="p-lg-4 px-3 py-4 py-md-0 "
    >
      <div className="w-100  ">
        {/* <p className='mb-2 text-white'>Let's  discuss your project ideas and bring it to life. Shall we?</p> */}
        <input
          // required
          type="text"
          className="w-100 py-2 px-2 rounded-3 border-0 mb-2"
          placeholder="Your Name"
          name="fullName"
          value={formData.fullName}
          onChange={handleChange}
        />
        {errors.fullName ? (
          <small className="error text-danger">{errors.fullName}</small>
        ) : (
          <div className="pb-4"></div>
        )}
      </div>

      <div className="w-100 mt-2">
        <input
          type="text"
          className="w-100 py-2 px-2 rounded-3 border-0 mb-2"
          placeholder="abc@email.com"
          name="email"
          value={formData.email}
          onChange={handleChange}
        />
        {errors.email ? (
          <small className="error text-danger">{errors.email}</small>
        ) : (
          <div className="pb-4"></div>
        )}
      </div>

      <div className="w-100 mt-2">
        {/* <input
            required
            type="number"
            className="w-100 py-2 px-2 rounded-3 border-0 mb-2"
            minLength={10}
            maxLength={10}
            placeholder="9876543210"
            name="contactNo"
            onChange={handleChange}
          /> */}
        <input
          className="form-control"
          name="contactNumber"
          placeholder="987XXX3210"
          value={formData.contactNumber}
          type="number"
          maxLength={10}
          minLength={10}
          onChange={handleChange}
          style={{
            fontWeight: "400",
          }}
        />

        {/* <div
            className="error text-danger"
            id="contactNoError"
            style={{ height: "20px", width: "150px" }}
          ></div> */}
        {errors.contactNumber ? (
          <small className="error text-danger">{errors.contactNumber}</small>
        ) : (
          <div className="pb-4"></div>
        )}
      </div>

      <div className="w-100 mt-2">
        <textarea
          rows="4"
          cols="50"
          className="w-100 py-2 px-2 rounded-3 border-0 mb-2"
          placeholder="Type your message here!"
          name="message"
          value={formData.message}
          onChange={handleChange}
        />
        {errors.message ? (
          <small className="error text-danger">{errors.message}</small>
        ) : (
          <div className="pb-4"></div>
        )}
      </div>

      <div className="captcha w-100 pt-2">
        <div className="h3 mob-wid text-center w-100 user-select-none bg-black p-2 rounded-3 text-white">
          <span className="text-decoration-line-through pe-2">{captcha}</span>
          <span className="text-primary" onClick={refreshString}>
            <IoIosRefresh />
          </span>
        </div>
        <input
          type="text"
          name="captcha"
          value={inputText}
          placeholder="Enter Captcha"
          className="w-100 mt-2 mob-wid py-2 px-2 rounded-3 border-0 "
          onChange={(e) => {
            checkCaptcha(e);
          }}
        />
        {errors.captcha ? (
          <small className="error text-danger">{errors.captcha}</small>
        ) : (
          <div className="pb-4"></div>
        )}
      </div>

      <input
        type="submit"
        id="btn"
        className="btn mt-4 w-100 contact-btn px-5 rounded-5 py-2 "
        value="Send Message"
      />
    </form>
  );
}
