export const ProjectItem = [
  // {
  //   img: require("../../images/portfolioImages/Finexa1.png"),
  //   heading: "Finex Capital",
  //   subHeading: "E-commerce Website",
  //   popImg: [
  //     {
  //       pop: require("../../images/finexapopup1.png"),
  //     },
  //     {
  //       pop: require("../../images/finexapopup2.png"),
  //     },
  //   ],
  //   para: [
  //     {
  //       para: "Finexa Capital aimed to streamline financial services, foster closer client relationships, and display their comprehensive loan portfolio, wide range of SME services with a one-stop solution for diverse customer needs.Their vision was to create a digital marvel that embodied their unwavering commitment to accessible and innovative finance. It facilitates seamless interactions, providing eligibility check, EMI calculator along with WhatsApp chat feature for customer inquiries.",
  //     },
  //     {
  //       para: "",
  //     },
  //   ],
  //   link: "https://finexacapital.com/",
  //   techs: "Figma, HTML, CSS, React JS",
  // },
  // {
  //   img: require("../../images/portfolioImages/AP.png"),
  //   heading: "AP Enterprises",
  //   subHeading: "Invoicing Webapp",
  //   popImg: [
  //     {
  //       pop: require("../../images/Appopup1.png"),
  //     },
  //     {
  //       pop: require("../../images/Appopup2.png"),
  //     },
  //   ],
  //   para: [
  //     {
  //       para: '"A P Enterprises" is a client specific user-friendly web app for efficient invoice management, enabling the admin to generate, edit, and track invoices, while also providing a secure PDF generation and download functionalities.',
  //     },
  //     {
  //       para: "The project ensures data security with robust authentication and authorization mechanisms, enhancing productivity through a responsive design and seamless access across devices.",
  //     },
  //   ],
  //   link: "https://apbusiness.co.in/",
  //   techs:
  //     "Figma, HTML, CSS, React JS, Node JS, MongoDB, Google cloud platform",
  // },

  {
    img: require("../../images/portfolioImages/Sanjoli.png"),
    heading: "Sanjoli ",
    subHeading: "E-commerce",
    popImg: [
      {
        pop: require("../../images/snajolipopup1.png"),
      },
      {
        pop: require("../../images/snajolipopup2.png"),
      },
    ],
    para: [
      {
        para: "SanjoliSarees.com is an E-commerce platform providing customers with an interface to purchase desired sarees among the listed products by create an engaging and seamless user experience. Sanjoli Sarees is one of the leading saree brands in Gujarat with a sale of over 10 lacs sarees per year.",
      },
      {
        para: "It is one of the leading saree brands in Gujarat.",
      },
    ],
    link: "https://www.sanjolisarees.com/",
    // techs:
    //   "Figma, HTML, CSS, React JS, Node JS, MongoDB, Google cloud platform",
  },

  {
    img: require("../../images/portfolioImages/chavi11.png"),
    heading: "Chhavi ",
    subHeading: "Interior Design and Architecture",
    popImg: [
      {
        pop: require("../../images/chavipopup1.png"),
      },
      {
        pop: require("../../images/chavipopup2.png"),
      },
    ],
    para: [
      // {
      //   para: "Studio Chhavi sought a way to streamline their design processes, enhance client collaboration, and present their portfolio in an engaging manner through intuitive user interfaces.",
      // },
      {
        para:"Studio Chhavi sought to streamline their design processes, enhance client collaboration, and present their portfolio in an engaging manner through intuitive user interfaces. We developed a digital solution that aligns with their vision of elegance and innovation, providing a seamless experience for both clients and designers.By integrating advanced design tools and features, Chhavi Studio can now showcase their work effectively while fostering stronger relationships with clients."
      },
      {
        para: "A digital solution that aligned with their vision of elegance and innovation.",
      },
    ],
    link: "https://studiochhavi.in/",
    // techs: "Figma, HTML, CSS, React JS",
  },

  // {
  //   img: require("../../images/portfolioImages/SS.png"),
  //   heading: "SS Service",
  //   subHeading: "IoT",
  //   popImg: [
  //     {
  //       pop: require("../../images/sspopup1.png"),
  //     },
  //     {
  //       pop: require("../../images/sspopup2.png"),
  //     },
  //   ],
  //   para: [
  //     {
  //       para: "Studio Chhavi sought a way to streamline their design processes, enhance client collaboration, and present their portfolio in an engaging manner through intuitive user interfaces.",
  //     },
  //     {
  //       para: "A digital solution that aligned with their vision of elegance and innovation.",
  //     },
  //   ],
  //   link: "https://studiochhavi.in/",
  //   techs: "Figma, HTML, CSS, React JS",
  // },

  // {
  //   img: require("../../images/portfolioImages/IZE.png"),
  //   heading: "IZE",
  //   subHeading: 'Print on demand e-commerce"',
  //   popImg: [
  //     {
  //       pop: require("../../images/Izepopup1.png"),
  //     },
  //     {
  //       pop: require("../../images/izepopup2.png"),
  //     },
  //   ],
  //   para: [
  //     {
  //       para: "Studio Chhavi sought a way to streamline their design processes, enhance client collaboration, and present their portfolio in an engaging manner through intuitive user interfaces.",
  //     },
  //     {
  //       para: "A digital solution that aligned with their vision of elegance and innovation.",
  //     },
  //   ],
  //   link: "https://studiochhavi.in/",
  //   techs: "Figma, HTML, CSS, React JS",
  // },
  // {
  //   img: require("../../images/portfolioImages/Amimg.png"),
  //   heading: "The Additive Minds",
  //   subHeading: "3D Printing and designing",
  //   popImg: [
  //     {
  //       pop: require("../../images/Ampopup1.png"),
  //     },
  //     {
  //       pop: require("../../images/Ampopup2.png"),
  //     },
  //   ],
  //   para: [
  //     {
  //       para: "Studio Chhavi sought a way to streamline their design processes, enhance client collaboration, and present their portfolio in an engaging manner through intuitive user interfaces.",
  //     },
  //     {
  //       para: "A digital solution that aligned with their vision of elegance and innovation.",
  //     },
  //   ],
  //   link: "https://studiochhavi.in/",
  //   techs: "Figma, HTML, CSS, React JS",
  // },
  // {
  //   img: require("../../images/portfolioImages/P2S.png"),
  //   heading: "Path2study",
  //   subHeading: "Fintech and Education service provider",
  //   popImg: [
  //     {
  //       pop: require("../../images/p2spopup1.png"),
  //     },
  //     {
  //       pop: require("../../images/p2spopup2.png"),
  //     },
  //   ],
  //   para: [
  //     {
  //       para: "Studio Chhavi sought a way to streamline their design processes, enhance client collaboration, and present their portfolio in an engaging manner through intuitive user interfaces.",
  //     },
  //     {
  //       para: "A digital solution that aligned with their vision of elegance and innovation.",
  //     },
  //   ],
  //   link: "https://studiochhavi.in/",
  //   techs: "Figma, HTML, CSS, React JS",
  // },
  {
    img: require("../../images/portfolioImages/ap1.png"),
    heading: "AP Enterprises",
    subHeading: "Invoice Discounting",
    popImg: [
      {
        pop: require("../../images/Appopup1.png"),
      },
      {
        pop: require("../../images/Appopup2.png"),
      },
    ],
    para: [
      // {
      //   para: '"A P Enterprises" is a client specific user-friendly web app for efficient invoice management, enabling the admin to generate, edit, and track invoices, while also providing a secure PDF generation and download functionalities.',
      // },
      // {
      //   para: "The project ensures data security with robust authentication and authorization mechanisms, enhancing productivity through a responsive design and seamless access across devices.",
      // },
      {
        para:"A P Enterprises is a client-specific, user-friendly web app designed for efficient invoice management. It enables the admin to generate, edit, and track invoices seamlessly while providing secure PDF generation and download functionalities.This solution streamlines the invoicing process, ensuring that businesses can manage their financial transactions with ease and accuracy. "
      }
    ],
    link: "https://apbusiness.co.in/",
    // techs:
    //   "Figma, HTML, CSS, React JS, Node JS, MongoDB, Google cloud platform",
  },
  {
    img: require("../../images/portfolioImages/IgnisExpo.png"),
    heading: "Ignis Expo",
    subHeading: "Real Estate",
    popImg: [
      {
        pop: require("../../images/ignispopup1.png"),
      },
      {
        pop: require("../../images/ignispopup2.png"),
      },
    ],
    para: [
      {
        para: "IGNIS redefines the real estate experience with a dynamic website designed to showcase buyers, sellers, and agents seamlessly. It offers intuitive navigation, comprehensive property listings, and interactive tools to streamline the home buying and selling process.",
      },
      // {
      //   para: "A digital solution that aligned with their vision of elegance and innovation.",
      // },
    ],
    link: "https://ignispropertyexpo.com/",
    // techs: "Figma, HTML, CSS, React JS",
  },
  {
    img: require("../../images/portfolioImages/Finexa11.png"),
    heading: "Finexa Capital",
    subHeading: "Fintech Website",
    popImg: [
      {
        pop: require("../../images/finexapopup1.png"),
      },
      {
        pop: require("../../images/finexapopup2.png"),
      },
    ],
    para: [
      // {
      //   para: "Finexa Capital aimed to streamline financial services, foster closer client relationships, and display their comprehensive loan portfolio, wide range of SME services with a one-stop solution for diverse customer needs. By offering a convenient and informative online platform, Finexa Capital seeks to establish itself as a trusted and customer-centric platform, empowering SMEs and individuals to make informed borrowing decisions and achieve their financial goals.",
      // },
      {
        para:"Finexa Capital aimed to streamline financial services, foster closer client relationships, and showcase their comprehensive loan portfolio along with a wide range of SME services. The platform serves as a one-stop solution for diverse customer needs, highlighting Finexa's expertise in providing tailored financial solutions.By leveraging cutting-edge technology and a customer-centric approach, Finexa Capital empowers clients to navigate the complex financial landscape with confidence."
      }
      // {
      //   para: "",
      // },
    ],
    link: "https://finexacapital.com/",
    // techs: "Figma, HTML, CSS, React JS",
  },
  {
    img: require("../../images/portfolioImages/kaash.png"),
    heading: "Kaash Studio",
    subHeading: "3D printing Studio ",
    popImg: [
      {
        pop: require("../../images/kashpopup1.png"),
      },
      {
        pop: require("../../images/kashpopup2.png"),
      },
    ],
    para: [
      // {
      //   para: "Kaash Studio is a 3D printing hub that offers bespoke solutions marked by unparalleled quality and unwavering commitment to two dynamic industries. They combine expertise, innovation, and state-of-the-art technology to bring your ideas to life in stunning detail offering two distinct services in  interior and commercial sector. ",
      // },
      {
        para:"Kaash Studio is a 3D printing hub that offers bespoke solutions characterized by unparalleled quality and a strong commitment to two dynamic industries. By combining expertise, innovation, and state-of-the-art technology, they bring ideas to life in stunning detail, providing tailored services for both the interior and commercial sectors.With a focus on precision and creativity, Kaash Studio ensures that every project meets the highest standards, and their dedication to excellence positions them as a trusted partner in the 3D printing landscape."
      }
      // {
      //   para: "A digital solution that aligned with their vision of elegance and innovation.",
      // },
    ],
    // link: "https://kaashstudio.com/",
    // techs: "Figma, HTML, CSS, React JS",
  },
  {
    img: require("../../images/portfolioImages/AM1.png"),
    heading: "The Additive Minds",
    subHeading: "3D Printing and Designing",
    popImg: [
      {
        pop: require("../../images/Ampopup1.png"),
      },
      {
        pop: require("../../images/Ampopup2.png"),
      },
    ],
    para: [
      // {
      //   para: "The Additive Minds is a 3D printing and manufacturing service provider specialized in precision and quality. They are specialized in providing services in commercial sector of the industry. The brand integrates advanced technologies and innovative processes to deliver high-quality prototypes, products, and components with precision and efficiency.",
      // },
      {
        para:"Additive Minds is a 3D printing and manufacturing service provider specializing in precision and quality for the commercial sector. They integrate advanced technologies and innovative processes to deliver high-quality prototypes, products, and components with exceptional efficiency.By focusing on the latest 3D printing technologies, Additive Minds ensures tailored solutions that meet the specific needs of their clients."
      }
      // {
      //   para: "A digital solution that aligned with their vision of elegance and innovation.",
      // },
    ],
    // link: "https://theadditiveminds.com/",
    // techs: "Figma, HTML, CSS, React JS",
  },
  {
    img: require("../../images/portfolioImages/P2S.png"),
    heading: "Path2study",
    subHeading: "Educational Service Provider",
    popImg: [
      {
        pop: require("../../images/p2spopup1.png"),
      },
      {
        pop: require("../../images/p2spopup2.png"),
      },
    ],
    para: [
      // {
      //   para: "The primary goal of the Path2Study is to provide scholarship and student loan information in an intuitive, user-friendly  way that caters to the needs of students seeking financial assistance for their education.",
      // },
      {
        para:"At Path2Study, we aim to provide scholarship and student loan information in an intuitive, user-friendly way that caters to students seeking financial assistance for their education. Our platform addresses the challenges students face when navigating scholarship and loan options, offering a comprehensive resource to help them make well-informed decisions about their financial future. With personalized tools and resources, Path2Study empowers students to explore various funding opportunities, ensuring they can pursue their educational goals with confidence."
      },
      // {
      //   para: "The project aims to address the challenges students face when navigating scholarship and loan options, providing them with a comprehensive resource to make well-informed decisions.",
      // },
    ],
    link: "https://path2study.com/",
    // techs: "Figma, HTML, CSS, React JS",
  },
  {
    img: require("../../images/portfolioImages/IZE1.png"),
    heading: "IZE",
    subHeading: "Print on Demand E-commerce",
    popImg: [
      {
        pop: require("../../images/Izepopup1.png"),
      },
      {
        pop: require("../../images/izepopup2.png"),
      },
    ],
    para: [
      // {
      //   para: "IZE, a Print-on-Demand E-commerce platform empowering artists and designers to showcase their creations and merchandise in a lifelike 3D environment. The platform caters to both artists and customers, offering them distinct features to navigate the site efficiently.",
      // },
      {
        para: "At IZE, we developed a cutting-edge Print-on-Demand e-commerce platform that empowers artists and designers to showcase their creations in a lifelike 3D environment. The platform offers distinct features for both artists and customers, ensuring an efficient and engaging experience while navigating the site. With its intuitive interface and advanced visualization tools, IZÉ makes it easy for users to explore, customize, and purchase unique products directly from their favorite creators.",
      },
      // {
      //   para: "A digital solution that aligned with their vision of elegance and innovation.",
      // },
    ],
    // link: "https://studiochhavi.in/",
    // techs: "Figma, HTML, CSS, React JS",
  },
  {
    img: require("../../images/portfolioImages/SS.png"),
    heading: "SS Service",
    // subTitle: "Erosion Monitoring WebApp",
    subHeading: "Erosion Monitoring WebApp",
    popImg: [
      {
        pop: require("../../images/sspopup1.png"),
      },
      {
        pop: require("../../images/sspopup2.png"),
      },
    ],
    para: [
      {
        para: "We have engineered a state-of-the-art IoT device and accompanying web application to revolutionize erosion monitoring. This cutting-edge solution is designed to provide real-time data and advanced analytics, ensuring precise monitoring and effective management of erosion in various environments.Our custom-built IoT device is equipped with high-precision sensors that continuously monitor and record critical data, including running hours and erosion percentage. The integrated web application serves as the central hub for data visualisation and analysis. It displays comprehensive metrics and analytics derived from the IoT device, offering users a detailed overview of erosion trends. The platform's intuitive interface enables users to access critical insights and calculations with ease, empowering them to make informed decisions to mitigate risks and extend the lifespan of their infrastructure.",
      },
      // {
      //   para: "A digital solution that aligned with their vision of elegance and innovation.",
      // },
    ],
    // link: "https://studiochhavi.in/",
    // techs: "Figma, HTML, CSS, React JS",
  },
];