import React, { useState, useEffect } from "react";
import "./Animation.css";
import { Swiper, SwiperSlide } from "swiper/react";
// import { MdOutlineArrowForwardIos } from "react-icons/md";
// import { MdOutlineArrowBackIosNew } from "react-icons/md";
import {
  Navigation,
  Autoplay,
  Pagination,
  // Parallax,
  Scrollbar,
  A11y,
} from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import "swiper/css";
// import "swiper/css/pagination";
// import "swiper/css/navigation";
import img1 from "../../images/Backgroundmob.png";

import { CustomerItems } from "./CustomerItems";

const Animation = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array ensures the effect runs only once after initial render

  const handleSlideChange = (swiper) => {
    const activeIndex = swiper.realIndex;
    setCurrentIndex(activeIndex);
  };
  const handleAnimationStart = () => {
    setCurrentIndex(0);
  };
  console.log("windowWidth > 2520", windowWidth > 2520);
  return (
    <>
      <div className="">
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
          // spaceBetween={50}
          slidesPerView={1}
          autoplay={{ delay: 5000 }}
          speed={2500}
          // navigation={true}
          // pagination={{ clickable: true }}
          navigation={windowWidth > 1900 ? false : true}
          pagination={windowWidth > 1900 ? { clickable: true } : false}
          style={
            windowWidth > 4000
              ? { height: "1100px" }
              : windowWidth > 2519
                ? { height: "820px" }
                : { height: "640px" }
          }
          // style={{ height: "580px" }}
          // pagination={ clickable: true }
          className="d-lg-block d-none mb-4 d-sm-none m-0 p-0 "
        >
          {CustomerItems.map((data, i) => {
            console.log("iteminimage", CustomerItems);
            return (
              <SwiperSlide>
                {/* <div className="container customer-css pt-5"> */}
                {/* <div className="d-flex flex-wrap justify-content-between ">
                 */}

                {/* <div
                    className={
                      seconndState === true ? "invisible" : "col-6 col-md-12 "
                    }
                   
                  > */}

                {/* <div>
                      <img src={data.img} className="img-fluid"/>
                    </div>
                    <div
                      className={
                        state === true
                          ? "animation col-7 pe-5 "
                          : "animation-return col-7"
                      }
                     
                    > */}
                {/* <div className="swiper-card-txt p-5  ps-5">
                        <p className="btn btn-danger text-black  rounded-5 bg-white border">
                          {data.subTitle}
                        </p>
                        {data.para.map((instance, j) => {
                          return (
                            <p className="pt-2 col-9" key={j}>
                              {instance.p}
                            </p>
                          );
                        })}
                        <p className="pt-4 ">
                          <span className="fw-medium">
                            Technologies used :{" "}
                          </span>{" "}
                          {data.techs}
                        </p>
                        <h5 className="fw-bold pb-2">{data.title}</h5>
                      </div> */}
                {/* </div> */}

                {/* <div
                      onMouseOver={hoverAnimation}
                      onMouseOut={handleMouseOutAnimation}
                      className="animation2 col-5 "
                    >
                      <img
                        src={data.img}
                        alt="Project Img"
                        className="img-fluid rounded-5"
                      />
                    </div> */}
                {/* </div> */}

                {/* <div
                     className={
                       state === true ? "invisible" : "col-6 col-md-12"
                     }
             
                  > */}
                {/* <div
                      className={
                        seconndState === true
                          ? "col-8 animation-second"
                          : "animation-second-reverse pe-5 "
                      }
                    
                      
                    > */}

                <div className="m-5">
                  <div className="px-3 mx-auto">
                    <div className="swiper-card-txt  rounded-5 col-11 mx-auto d-flex justify-content-between ">
                      <div className="col-5">
                        <img src={data.img} className={`${windowWidth > 2520 ? 'w-75' : 'w-100'} rounded-5 p-3 `} />
                      </div>
                      <div
                        className="col-6 me-5 mt-5"
                        // style={
                        //   windowWidth > 2520
                        //     ? { paddingTop: "20rem" }
                        //     : { paddingTop: "" }
                        // }
                      >
                        <p className=" text-black  rounded-5 bg-white border col-7 p-1 text-center">
                          {data.subTitle}
                        </p>

                        {data.para.map((instance, j) => {
                          return (
                            <p
                              style={{ textAlign: "justify" }}
                              className="pt-3 col-10"
                              key={j}
                            >
                              {instance.p}
                            </p>
                          );
                        })}
                        {/* <p style={{ textAlign: "justify" }} className="pt-4">
                      <p className="fw-medium">Technologies used : </p>{" "}
                      {data.techs}
                    </p> */}
                        <h5 className="fw-bold col-7 mt-2">{data.title}</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
        {/* ----------------------------------MOBILE-VIEW---------------------------------- */}

        {/* <div className="mobile-swiper "> */}
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
          slidesPerView={1}
          // pagination={{ clickable: true }}
          className=" d-lg-none  d-sm-block  "
          autoplay={{
            delay: 2000,
          }}
          style={{ height: "700px" }}
        >
          {CustomerItems.map((data, i) => {
            return (
              <>
                <SwiperSlide>
                  <div className="py-5 p-4">
                    <div
                      // className={`img2div d-lg-none d-sm-block px-3 ${
                      //   isHovered === true ? "animation-first" : "animationdown"
                      // }`}
                      className="img2div d-lg-none d-sm-block "
                    >
                      <img
                        src={data.img}
                        alt="Project Img"
                        className="img-fluid w-100 rounded-5 p-4"
                        // style={{ height: "150px" }}
                      />
                      <div className=" mx-4 ">
                        <p className="text-black rounded-5 bg-white border text-center fontsizetitle col-9 p-1 mx-3">
                          {data.subTitle}
                        </p>
                        {data.para.map((instance, j) => {
                          return (
                            <p
                              style={{ textAlign: "justify" }}
                              className="col-10  fontmob mt-1 mx-3"
                              key={j}
                            >
                              {instance.p}
                            </p>
                          );
                        })}
                        <h5 className="fw-bold mx-3 col-7 mt-2 pt-0">
                          {data.title}
                        </h5>
                      </div>
                    </div>

                    <div className=" d-lg-none d-block img1div  ">
                      <img
                        src={img1}
                        alt="Project Img"
                        className="img-fluid  pb-3 "
                        style={{ height: "672px", width: "520px" }}
                      />
                    </div>
                  </div>
                </SwiperSlide>
              </>
            );
          })}
        </Swiper>
        {/* </div> */}
        {/* mob swiper end */}
      </div>

      {/* <div className="d-flex justify-content-between p-5">
        {CustomerItems.map((data, j) => (
          <div
            key={j}
            className={`col-4 mt-5 ${
              currentIndex === j ? "textdiv-animation" : ""
            }`}
          >
            <div>
              <h5 className="title">{data.title}</h5>
              {data.para.map((instance, idx) => (
                <p key={idx} className="paracontent">
                  {instance.p}
                </p>
              ))}
              <label className="mt-2">Technologies</label>
              <p className="paracontent">{data.techs}</p>
            </div>
          </div>
        ))}

        <div
          className={`col-4 mt-5 ${
          currentIndex ? "textdiv-animation" : ""
          }`}
        > 
          <div>
            <h5 className="title">{CustomerItems[currentIndex]?.title}</h5>
            {CustomerItems[currentIndex]?.para?.map((instance, idx) => (
              <p key={idx} className=" paracontent">
                {instance.p}
              </p>
            ))}
            <label className="mt-2">Technologies</label>
            <p className="paracontent">{CustomerItems[currentIndex]?.techs}</p>
          </div>
        </div>

        <div className="col-8 ">
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, Autoplay]}
            
            slidesPerView={3}
            // navigation
            // pagination
            className="w-100 m-0"
            style={{ height: "300px" }}
            autoplay={{ delay: 3000, disableOnInteraction: false }}
            loop={true}
            onSlideChange={(swiper) => handleSlideChange(swiper)}
            onInit={() => handleAnimationStart()}
          >
            <div className="container">
              {CustomerItems?.map((d, j) => (
                <SwiperSlide key={j} className="mt-5">
                  {currentIndex === j ? (
                    <div className="first-slide col-11 mx-5">
                      <img
                        src={d.img}
                        className="img-fluid first-image rounded-4 mt-5"
                      />
                    </div>
                  ) : (
                    <div className="other-slides col-8 mx-5 mt-3">
                      <img src={d.img} className="img-fluid rounded-4 mx-5" />
                    </div>
                  )}
                </SwiperSlide>
              ))}
            </div>
          </Swiper>
          <div className="col-12  d-flex  align-items-center">
            <button className="col-6  text-end">
              <MdOutlineArrowBackIosNew  size={20} onClick={()=>handleSlideChange()}/>
            </button>
            <button className="col-6 text-start">
               <MdOutlineArrowForwardIos size={20} onClick={()=>handleSlideChange()}/>
            </button>
          </div>
        </div>
      </div> */}

      {/* code for swiper new */}
    </>
  );
};

export default Animation;
