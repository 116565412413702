import React, { useEffect, useState } from "react";
import "./Stats.css";
import icon from "../../images/homeImages/icon-stats.png";
import { FaArrowTrendUp } from "react-icons/fa6";

const Stats = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array ensures the effect runs only once after initial render
  // explore all technologies
  return (
    <>
      <div className="Stats-container mt-3">
        <div
          className={`${windowWidth > 2520 ? "container-fluid" : "container"}`}
        >
          {/* <div className="container"> */}
          {/* <div className="d-flex flex-wrap justify-content-center align-items-center col-12  py-md-5 mb-md-5 pb-5 pb-md-0 ">
            <div className=" col-md-6 col-12 ">
              <div className="text-center ">
                <img src={icon} className="img-fluid" />
              </div>
              <h3 className="text-center my-3 my-md-3 my-lg-0 p-2">
                Revealing Numbers and Metrics
              </h3>
              <p className="text-center text-secondary px-5 mb-4 mb-md-0">
                Our journey of groundbreaking concepts drive progress and
                redefine possibilities!
              </p>
            </div>
            <div className="col-md-6 mt-3 mt-md-0  statsdiv col-12">
              <div className="d-flex flex-wrap justify-content-around">
                <div className="p-1">
                  <div className="d-flex flex-wrap justify-content-around">
                    <h1 className="stats-color ">25+</h1>
                    <div className="px-2 me-3">
                      <FaArrowTrendUp fill="#0FA4D2" size={40} />{" "}
                    </div>
                  </div>
                  <p>Delivered Projects</p>
                </div>
                <div className="vr "></div>
                <div className="p-1 ">
                  <div className="d-flex flex-wrap justify-content-around">
                    <h1 className="stats-color">5+</h1>
                    <div className="px-2 me-3">
                      <FaArrowTrendUp fill="#0FA4D2" size={40} />{" "}
                    </div>
                  </div>
                  <p>Ongoing Projects</p>
                </div>
                <div className="vr "></div>
              </div>
              <div className="d-flex flex-wrap justify-content-around mt-5">
                <div className="p-1">
                  <div className="d-flex flex-wrap justify-content-around">
                    <h1 className="stats-color ">21+</h1>
                    <div className="px-2 me-3">
                      <FaArrowTrendUp fill="#0FA4D2" size={40} />{" "}
                    </div>
                  </div>
                  <p>Happy Clients</p>
                </div>
                <div className="vr m"></div>
                <div className="p-1">
                  <div className="d-flex flex-wrap justify-content-around">
                    <h1 className="stats-color">15+</h1>
                    <div className="px-2 me-3">
                      <FaArrowTrendUp fill="#0FA4D2" size={40} />{" "}
                    </div>
                  </div>
                  <p>Team Size</p>
                </div>
                <div className="vr "></div>
              </div>
            </div>
          </div> */}

          <div className="  py-md-5 mb-md-5 pb-5 pb-md-0 ">
            <div className="">
              <div className="text-center ">
                <img src={icon} className="img-fluid" />
              </div>
              <h3 className="text-center my-3 my-md-3 my-lg-0 p-2">
                Revealing Numbers and Metrics
              </h3>
              <p className="text-center text-secondary px-5 mb-4 mb-md-0">
                Our journey of groundbreaking concepts drive progress and
                redefine possibilities!
              </p>
            </div>
            <div className=" mt-3 mt-md-4  statsdiv ">
              <div className="d-flex justify-content-between px-md-5 px-3">
                <div className="p-1">
                  <div className="d-flex flex-wrap justify-content-around">
                    <h1 className="stats-color ">25+</h1>
                    <div className="px-2 mx-md-3">
                      <FaArrowTrendUp fill="#0FA4D2" size={40} />{" "}
                    </div>
                  </div>
                  <p>Delivered Projects</p>
                </div>
                <div className="vr  me-md-3"></div>
                <div className="p-1">
                  <div className="d-flex flex-wrap justify-content-around">
                    <h1 className="stats-color">5+</h1>
                    <div className="px-2 mx-md-3">
                      <FaArrowTrendUp fill="#0FA4D2" size={40} />{" "}
                    </div>
                  </div>
                  <p>Ongoing Projects</p>
                </div>
                <div className="vr me-md-3 "></div>
                <div className="p-1 ">
                  <div className="d-flex flex-wrap justify-content-around">
                    <h1 className="stats-color">21+</h1>
                    <div className="px-2 mx-md-3">
                      <FaArrowTrendUp fill="#0FA4D2" size={40} />{" "}
                    </div>
                  </div>
                  <p>Happy Clients</p>
                </div>
                <div className="vr  me-md-3"></div>
                <div className="p-1 ">
                  <div className="d-flex flex-wrap justify-content-around">
                    <h1 className="stats-color">15+</h1>
                    <div className="px-2 mx-md-3">
                      <FaArrowTrendUp fill="#0FA4D2" size={40} />{" "}
                    </div>
                  </div>
                  <p>Team Size</p>
                </div>
                <div className="vr  me-md-3"></div>
              </div>
              {/* <div className="d-flex flex-wrap justify-content-around mt-5">
                <div className="p-1">
                  <div className="d-flex flex-wrap justify-content-around">
                    <h1 className="stats-color ">21+</h1>
                    <div className="px-2 me-3">
                      <FaArrowTrendUp fill="#0FA4D2" size={40} />{" "}
                    </div>
                  </div>
                  <p>Happy Clients</p>
                </div>
                <div className="vr m"></div>
                <div className="p-1">
                  <div className="d-flex flex-wrap justify-content-around">
                    <h1 className="stats-color">15+</h1>
                    <div className="px-2 me-3">
                      <FaArrowTrendUp fill="#0FA4D2" size={40} />{" "}
                    </div>
                  </div>
                  <p>Team Size</p>
                </div>
                <div className="vr "></div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Stats;
