import React, { useEffect, useState } from "react";
import "./solution.css";

import Technologies from "../components/Common/Technologies";
import Solutions from "../components/Common/Solutions";
import { useLocation } from "react-router-dom";

const SolutionsPage = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const location = useLocation();
  const currentPath = location.pathname;
  useEffect(() => {
    console.log("currentPath", currentPath);
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [location.pathname]); // Empty dependency array ensures the effect runs only once after initial render
  // explore all technologies
  return (
    <>
      {/* Banner  */}
      <div className="solution-banner position-relative">
        <img
          src={require("../images/solutionPageImages/banner-solution.png")}
          width="100%"
          alt="Banner"
          className="img-fluid"
        />
        <div className="banner-inner text-center text-white">
          <h1 className="mb-lg-4">Solutions</h1>
          <p>
            Manage complex IT issues with our simplified technologies to
            overcome challenges and drive success in the digital era.
          </p>
        </div>
      </div>

      {/* Services  */}
      <div className="main-technology pb-2">
        <div className="container" id="Technology">
          <div className="text-md-end text-center">
            <h1 className="light-heading py-4"># OUR EXPERTISE</h1>
          </div>
          <h1 className="mb-4 text-center">
            Simplify Your Technology with Our Suite of{" "}
            <span className="primary-txt"> IT Services </span>
          </h1>
          <p className=" col-md-6 mx-auto text-center text-secondary mb-5">
            Your IT Journey Starts Here: Uncover Expert Solutions Crafted to
            Elevate Your Business Growth. Experience Customized Services for
            Your Success Story.
          </p>
          <Technologies />
        </div>
      </div>
      {/* Solutions  */}
      <div className="bg-light-sky pt-5 page-solutions pb-3" id="Soltion">
        <div className="container">
          <div className="text-center mb-4">
            <h1 className="mb-3">Solutions We Deliver</h1>
            <p className="secondary-light-txt">
              Effortlessly manage your cloud and on-premises systems with our
              comprehensive support for infrastructure and applications.
            </p>
          </div>
          <Solutions />
        </div>
      </div>

      {/* Innovation */}
      {/* <div className="">
                <div className="d-md-none">
                    <img src={require('../images/solutionPageImages/innovation-2.png')} alt="Innovation " className="img-fluid w-100" />
                </div>
                <div className=" position-relative invation">
                    <img src={require('../images/solutionPageImages/innovation-1.png')} alt="Back" className="img-fluid w-100" />
                    <div className="invo-inside text-center">
                        <h1>Innovation driven by new <span className='primary-txt'> Technologies </span></h1>
                        <p>Effortlessly manage your cloud and on-premises systems with our comprehensive support for infrastructure and applications.</p>
                    </div>
                </div>
                <div className="col-md-6 d-none d-md-block">
                    <img src={require('../images/solutionPageImages/innovation-2.png')} alt="Innovation " className="img-fluid w-100" />
                </div>
            </div> */}

      <div className="solution-we-deliver pb-3">
        <img
          src={require("../images/solutionPageImages/galaxybig.png")}
          className="img-fluid d-none d-md-block d-lg-block"
        />
        <img
          src={require("../images/solutionPageImages/galaxy-mobile.png")}
          className="img-fluid d-lg-none d-md-none d-block"
        />
        <div className="mt-5 mb-5 text-center">
          <h1>
            Innovation driven by new<br></br>
            <span className="primary-txt"> Technologies </span>
          </h1>

          <p className=" col-md-7  mx-auto text-center text-secondary">
            Effortlessly manage your cloud and on-premises systems with our
            comprehensive support for infrastructure and applications.
          </p>
        </div>
      </div>

      {/* Technologies */}
      <div className="bg-light-sky pb-4">
        <div className="container">
          <div className="text-md-end text-center">
            <h1 className="light-heading py-md-4 pt-4 text-uppercase">
              #Technologies
            </h1>
          </div>

          <div className="techs">
            {/* Frontend  */}
            <div className="d-flex flex-wrap align-items-center pt-4">
              <div className="col-md-3 col-12 pe-md-5 ">
                <p className="ps-md-5 mb-0 fw-medium pb-4 pb-md-0 fs-5">
                  Frontend
                </p>
              </div>
              <div className="col-md-9 col-12">
                <div className="imgs d-flex overflow-auto">
                  <div className="col-3 col-md-2 pb-3 pb-md-0 text-center pe-2">
                    <img
                      src={require("../images/solutionPageImages/techUsed/javascript.png")}
                      alt="Img"
                      width="100%"
                      className="img-fluid "
                    />
                    <p className="secondary-light-txt ">Javascript</p>
                  </div>
                  <div className="col-3 col-md-2 pb-3 pb-md-0 text-center pe-2">
                    <img
                      src={require("../images/solutionPageImages/techUsed/react.png")}
                      alt="Img"
                      width="100%"
                      className="img-fluid "
                    />
                    <p className="secondary-light-txt ">React JS</p>
                  </div>
                  <div className="col-3 col-md-2 pb-3 pb-md-0 text-center pe-2">
                    <img
                      src={require("../images/solutionPageImages/techUsed/angular.png")}
                      alt="Img"
                      width="100%"
                      className="img-fluid "
                    />
                    <p className="secondary-light-txt ">Angular</p>
                  </div>
                  <div className="col-3 col-md-2 pb-3 pb-md-0 text-center pe-2">
                    <img
                      src={require("../images/solutionPageImages/techUsed/html.png")}
                      alt="Img"
                      width="100%"
                      className="img-fluid "
                    />
                    <p className="secondary-light-txt ">HTML5</p>
                  </div>
                  <div className="col-3 col-md-2 pb-3 pb-md-0 text-center pe-2">
                    <img
                      src={require("../images/solutionPageImages/techUsed/css.png")}
                      alt="Img"
                      width="100%"
                      className="img-fluid "
                    />
                    <p className="secondary-light-txt ">CSS</p>
                  </div>
                  <div className="col-3 col-md-2 pb-3 pb-md-0 text-center pe-2">
                    <img
                      src={require("../images/solutionPageImages/techUsed/Wordpresstech.png")}
                      alt="Img"
                      width="100%"
                      className="img-fluid "
                    />
                    <p className="secondary-light-txt ">WordPress</p>
                  </div>
                </div>
              </div>
            </div>
            <hr />

            {/* Backend  */}
            <div className="d-flex flex-wrap align-items-center pt-4">
              <div className="col-md-3 col-12 pe-md-5 ">
                <p className="ps-md-5 mb-0 fw-medium pb-4 pb-md-0m fs-5">
                  Backend
                </p>
              </div>
              <div className="col-md-9 col-12">
                <div className="imgs d-flex overflow-auto">
                  <div className="col-3 col-md-2 pb-3 pb-md-0 text-center pe-2">
                    <img
                      src={require("../images/solutionPageImages/techUsed/node.png")}
                      alt="Img"
                      width="100%"
                      className="img-fluid "
                    />
                    <p className="secondary-light-txt ">Node JS</p>
                  </div>
                  <div className="col-3 col-md-2 pb-3 pb-md-0 text-center pe-2">
                    <img
                      src={require("../images/solutionPageImages/techUsed/express.png")}
                      alt="Img"
                      width="100%"
                      className="img-fluid "
                    />
                    <p className="secondary-light-txt ">Express</p>
                  </div>
                  <div className="col-3 col-md-2 pb-3 pb-md-0 text-center pe-2">
                    <img
                      src={require("../images/solutionPageImages/techUsed/java.png")}
                      alt="Img"
                      width="100%"
                      className="img-fluid "
                    />
                    <p className="secondary-light-txt ">Java</p>
                  </div>
                  <div className="col-3 col-md-2 pb-3 pb-md-0 text-center pe-2">
                    <img
                      src={require("../images/solutionPageImages/techUsed/python.png")}
                      alt="Img"
                      width="100%"
                      className="img-fluid "
                    />
                    <p className="secondary-light-txt ">Python</p>
                  </div>
                  <div className="col-3 col-md-2 pb-3 pb-md-0 text-center pe-2">
                    <img
                      src={require("../images/solutionPageImages/techUsed/microsoft-net.png")}
                      alt="Img"
                      width="100%"
                      className="img-fluid "
                    />
                    <p className="secondary-light-txt ">Microsoft.net</p>
                  </div>
                </div>
              </div>
            </div>
            <hr />

            {/* Mobile  */}
            <div className="d-flex flex-wrap align-items-center pt-4">
              <div className="col-md-3 col-12 pe-md-5 ">
                <p className="ps-md-5 mb-0 fw-medium pb-4 pb-md-0um fs-5">
                  Mobile
                </p>
              </div>
              <div className="col-md-9 col-12">
                <div className="imgs d-flex overflow-auto">
                  <div className="col-3 col-md-2 text-center pe-2">
                    <img
                      src={require("../images/solutionPageImages/techUsed/ios.png")}
                      alt="Img"
                      width="100%"
                      className="img-fluid "
                    />
                    <p className="secondary-light-txt ">iOS</p>
                  </div>
                  <div className="col-3 col-md-2 text-center pe-2">
                    <img
                      id="im"
                      src={require("../images/android4.png")}
                      alt="Img"
                      width="100%"
                      className="img-fluid "
                    />
                    <p className="secondary-light-txt ">Android</p>
                  </div>
                  <div className="col-3 col-md-2 text-center pe-2">
                    <img
                      src={require("../images/solutionPageImages/techUsed/flutter.png")}
                      alt="Img"
                      width="100%"
                      className="img-fluid "
                    />
                    <p className="secondary-light-txt ">Flutter</p>
                  </div>
                  <div className="col-3 col-md-2 text-center pe-2">
                    <img
                      id="im"
                      src={require("../images/react-native6.png")}
                      alt="Img"
                      width="100%"
                      className="img-fluid "
                    />
                    <p className="secondary-light-txt ">React Native</p>
                  </div>
                </div>
              </div>
            </div>
            <hr />

            {/* Testing  */}
            <div className="d-flex flex-wrap align-items-center pt-4">
              <div className="col-md-3 col-12 pe-md-5 ">
                <p className="ps-md-5 mb-0 fw-medium pb-4 pb-md-0m fs-5">
                  Testing
                </p>
              </div>
              <div className="col-md-9 col-12">
                <div className="imgs d-flex overflow-auto">
                  <div className="col-3 col-md-2 text-center pe-2">
                    <img
                      src={require("../images/solutionPageImages/techUsed/postman.png")}
                      alt="Img"
                      width="100%"
                      className="img-fluid "
                    />
                    <p className="secondary-light-txt ">Postman</p>
                  </div>
                  <div className="col-3 col-md-2 text-center pe-2">
                    <img
                      src={require("../images/solutionPageImages/techUsed/jmeter.png")}
                      alt="Img"
                      width="100%"
                      className="img-fluid "
                    />
                    <p className="secondary-light-txt ">Apache JMeter</p>
                  </div>
                  <div className="col-3 col-md-2 text-center pe-2">
                    <img
                      src={require("../images/solutionPageImages/techUsed/selenium.png")}
                      alt="Img"
                      width="100%"
                      className="img-fluid "
                    />
                    <p className="secondary-light-txt ">Selenium</p>
                  </div>
                  <div className="col-3 col-md-2 text-center pe-2">
                    <img
                      src={require("../images/solutionPageImages/techUsed/appium.png")}
                      alt="Img"
                      width="100%"
                      className="img-fluid "
                    />
                    <p className="secondary-light-txt ">Appium</p>
                  </div>
                </div>
              </div>
            </div>
            <hr />

            {/* Database  */}
            <div className="d-flex flex-wrap align-items-center pt-4">
              <div className="col-md-3 col-12 pe-md-5 ">
                <p className="ps-md-5 mb-0 fw-medium pb-4 pb-md-0 fs-5">
                  Database
                </p>
              </div>
              <div className="col-md-9 col-12">
                <div className="imgs d-flex overflow-auto">
                  <div className="col-3 col-md-2 text-center pe-2">
                    <img
                      src={require("../images/solutionPageImages/techUsed/mongo.png")}
                      alt="Img"
                      width="100%"
                      className="img-fluid "
                    />
                    <p className="secondary-light-txt ">MongoDB</p>
                  </div>
                  <div className="col-3 col-md-2 text-center pe-2">
                    <img
                      src={require("../images/solutionPageImages/techUsed/redis.png")}
                      alt="Img"
                      width="100%"
                      className="img-fluid "
                    />
                    <p className="secondary-light-txt ">Redis</p>
                  </div>
                  <div className="col-3 col-md-2 text-center pe-2">
                    <img
                      src={require("../images/solutionPageImages/techUsed/mysql.png")}
                      alt="Img"
                      width="100%"
                      className="img-fluid "
                    />
                    <p className="secondary-light-txt ">MySQL</p>
                  </div>
                  <div className="col-3 col-md-2 text-center pe-2">
                    <img
                      src={require("../images/solutionPageImages/techUsed/firestore.png")}
                      alt="Img"
                      width="100%"
                      className="img-fluid "
                    />
                    <p className="secondary-light-txt ">Cloud Firestore</p>
                  </div>
                </div>
              </div>
            </div>
            <hr />

            {/* Infrastucture  */}
            <div className="d-flex flex-wrap align-items-center pt-4">
              <div className="col-md-3 col-12 pe-md-5 ">
                <p className="ps-md-5 mb-0 fw-medium pb-4 pb-md-0 fs-5">
                  Infrastructure
                </p>
              </div>
              <div className="col-md-9 col-12">
                <div className="imgs d-flex overflow-auto">
                  <div className="col-3 col-md-2 text-center pe-2">
                    <img
                      src={require("../images/solutionPageImages/techUsed/google-cloud.png")}
                      alt="Img"
                      width="100%"
                      className="img-fluid "
                    />
                    <p className="secondary-light-txt ">Google Cloud</p>
                  </div>
                  <div className="col-3 col-md-2 text-center pe-2">
                    <img
                      src={require("../images/solutionPageImages/techUsed/aws.png")}
                      alt="Img"
                      width="100%"
                      className="img-fluid "
                    />
                    <p className="secondary-light-txt ">AWS</p>
                  </div>
                  <div className="col-3 col-md-2 text-center pe-2">
                    <img
                      src={require("../images/solutionPageImages/techUsed/kubrnet.png")}
                      alt="Img"
                      width="100%"
                      className="img-fluid "
                    />
                    <p className="secondary-light-txt ">Kubernetes</p>
                  </div>
                  <div className="col-3 col-md-2 text-center pe-2">
                    <img
                      src={require("../images/solutionPageImages/techUsed/cloudfunction.png")}
                      alt="Img"
                      width="100%"
                      className="img-fluid "
                    />
                    <p className="secondary-light-txt ">Cloud Function</p>
                  </div>
                  <div className="col-3 col-md-2 text-center pe-2">
                    <img
                      src={require("../images/solutionPageImages/techUsed/Docker.png")}
                      alt="Img"
                      width="100%"
                      className="img-fluid "
                    />
                    <p className="secondary-light-txt ">Docker</p>
                  </div>
                </div>
              </div>
            </div>
            <hr />

            {/* Design  */}
            <div className="d-flex flex-wrap align-items-center pt-4">
              <div className="col-md-3 col-12 pe-md-5 ">
                <p className="ps-md-5 mb-0 fw-medium pb-4 pb-md-0um fs-5">
                  Design
                </p>
              </div>
              <div className="col-md-9 col-12 pb-5 ">
                <div className="imgs d-flex overflow-auto ">
                  <div className="col-3 col-md-2 text-center pe-2">
                    <img
                      src={require("../images/solutionPageImages/techUsed/figma.png")}
                      alt="Img"
                      width="100%"
                      className="img-fluid "
                    />
                    <p className="secondary-light-txt ">Figma</p>
                  </div>
                  <div className="col-3 col-md-2 text-center pe-2">
                    <img
                      src={require("../images/solutionPageImages/techUsed/balsamiq.png")}
                      alt="Img"
                      width="100%"
                      className="img-fluid "
                    />
                    <p className="secondary-light-txt ">Balsamiq</p>
                  </div>
                  <div className="col-3 col-md-2 text-center pe-2">
                    <img
                      src={require("../images/solutionPageImages/techUsed/adobe-xd.png")}
                      alt="Img"
                      width="100%"
                      className="img-fluid "
                    />
                    <p className="secondary-light-txt ">Adobe XD</p>
                  </div>
                  <div className="col-3 col-md-2 text-center pe-2">
                    <img
                      src={require("../images/solutionPageImages/techUsed/sketch.png")}
                      alt="Img"
                      width="100%"
                      className="img-fluid "
                    />
                    <p className="secondary-light-txt  ">Sketch</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Expertise  */}
      <div className="bg-expertise py-5">
        <div className="container py-md-5 d-flex flex-wrap align-items-center">
          <div className="col-md-5 pad-right">
            <h1 className="mb-md-5">Sector Specific Expertise</h1>
            <p className="secondary-light-txt mb-0">
              We are skilled in addressing challenges with comprehensive
              knowledge of the intricacies within your industry that drive
              success to your Business.
            </p>
          </div>

          <div className="col-md-7">
            <div className="row d-flex flex-wrap justify-content-center">
              <div className="col-xl-3 col-lg-4 col-md-6 col-6 px-lg-4 pe-md-0 ps-md-5 px-md-5 py-5 pt-md-0">
                <div className="custom-card-style px-3 text-center sol-card">
                  <img
                    src={require("../images/solutionPageImages/expertise/health-care.png")}
                    alt="Sector Img"
                    width="100%"
                    className="img-fluid sol-img"
                  />
                  <p className="mb-0">Healthcare</p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-6 px-lg-4 pe-md-0 ps-md-5 px-md-5 py-5 py-md-0">
                <div className="custom-card-style px-3 text-center sol-card">
                  <img
                    src={require("../images/solutionPageImages/expertise/banking.png")}
                    alt="Sector Img"
                    width="100%"
                    className="img-fluid sol-img"
                  />
                  <p className="mb-0">Banking</p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-6 px-lg-4 pe-md-0 ps-md-5 px-md-5 pb-5 pb-md-0 ">
                <div className="custom-card-style px-3 text-center sol-card">
                  <img
                    src={require("../images/solutionPageImages/expertise/entertainment.png")}
                    alt="Sector Img"
                    width="100%"
                    className="img-fluid sol-img mt-2"
                  />
                  <p className="mb-0">Entertainment</p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-6 px-lg-4 pe-md-0 ps-md-5 px-md-5 pb-5">
                <div className="custom-card-style px-3 text-center sol-card">
                  <img
                    src={require("../images/solutionPageImages/expertise/manufacturing.png")}
                    alt="Sector Img"
                    width="100%"
                    className="img-fluid sol-img mt-2"
                  />
                  <p className="mb-0">Manufacturing</p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-6 px-lg-4 pe-md-0 ps-md-5 px-md-5 pb-5">
                <div className="custom-card-style px-3 text-center sol-card">
                  <img
                    src={require("../images/solutionPageImages/expertise/marketing-and-advertising.png")}
                    alt="Sector Img"
                    width="100%"
                    className="img-fluid sol-img"
                  />
                  <p className="mb-0">Marketing & Advertising</p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-6 px-lg-4 pe-md-0 ps-md-5 px-md-5 pb-5">
                <div className="custom-card-style px-3 text-center sol-card">
                  <img
                    src={require("../images/solutionPageImages/expertise/it-services.png")}
                    alt="Sector Img"
                    width="100%"
                    className="img-fluid sol-img"
                  />
                  <p className="mb-0">IT Services</p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-6 px-lg-4 pe-md-0 ps-md-5 px-md-5 pb-md-5 pb-1">
                <div className="custom-card-style px-3 text-center sol-card">
                  <img
                    src={require("../images/solutionPageImages/expertise/ecom.png")}
                    alt="Sector Img"
                    width="100%"
                    className="img-fluid sol-img"
                  />
                  <p className="mb-0 fs-6">
                    {/* {widthh < 300 ? "Tele-com" : "Tele-communication"} */}
                    E-commerce
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-6 px-lg-4 pe-md-0 ps-md-5 px-md-5 pb-md-5 pb-1">
                <div className="custom-card-style px-3 text-center sol-card">
                  <img
                    src={require("../images/solutionPageImages/expertise/hospitality.png")}
                    alt="Sector Img"
                    width="100%"
                    className="img-fluid sol-img "
                  />
                  <p className="mb-0">Hospitality</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Banner */}
      <img
        src={require("../images/solutionPageImages/banner-sectionbig.png")}
        className="img-fluid d-none d-md-block d-lg-block"
      />
      <img
        src={require("../images/solutionPageImages/solution-banner-mob.png")}
        className="img-fluid d-block d-md-none d-lg-none mobile-img"
      />
      {/* IT ecosystem */}

      <div className="it-ecosystem">
        <div
          className={`${
            windowWidth > 2519 ? "container-fluid-solution" : "container"
          } pb-4`}
        >
          <h1 className="mb-4 text-center mt-5">
            Your IT Ecosystem,
            <span className="primary-txt"> Perfectly Managed </span>
          </h1>
          <p className="text-secondary  col-md-6 text-center mx-auto">
            Effortlessly manage your cloud and on-premises systems with our
            comprehensive support for infrastructure and applications.
          </p>
          <div className="d-flex flex-wrap justify-content-between mt-5 pb-5 ">
            <div className="col-lg-3 col-md-6 col-12 pe-lg-1 pe-md-2 ">
              <div className="p-3  shadow bg-white rounded">
                <div className="d-flex flex-wrap justify-content-between">
                  <h5>Consulting</h5>
                  <div className=" col-2">
                    <img
                      src={require("../images/solutionPageImages/guidance.png")}
                      className="img-fluid "
                      width={42}
                    />
                  </div>
                </div>
                <p className="pt-1">
                  Leverage our expert guidance to optimize your IT strategies
                  and stay ahead of the curve.
                </p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-12 px-lg-1 pe-lg-1 px-lg-1 pe-lg-1 px-md-2  mt-lg-0">
              <div className=" p-3  shadow bg-white rounded ">
                <div className="d-flex flex-wrap justify-content-between">
                  <h5>Security</h5>
                  <div className="col-2">
                    <img
                      src={require("../images/solutionPageImages/arcticons.png")}
                      className="img-fluid"
                      width={42}
                    />
                  </div>
                </div>
                <p className="pt-1">
                  Strengthen your digital assets with our robust security
                  solutions, safeguarding your business from potential threats.
                </p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-12 px-lg-1 pe-lg-1  mt-md-3 mt-lg-0 pe-md-2">
              <div className="p-3  shadow bg-white rounded">
                <div className="d-flex flex-wrap justify-content-between">
                  <h5>Implementation</h5>
                  <div className="col-2">
                    <img
                      src={require("../images/solutionPageImages/electron.png")}
                      className="img-fluid"
                      width={42}
                    />
                  </div>
                </div>
                <p className="pt-2">
                  Seamlessly integrate new technologies into your operations,
                  enhancing efficiency and productivity
                </p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-12 px-lg-1  px-md-2 mt-lg-0 mt-md-3">
              <div className="p-3  shadow bg-white rounded">
                <div className="d-flex flex-wrap justify-content-between">
                  <h5>Migration</h5>
                  <div className="col-5">
                    <img
                      src={require("../images/solutionPageImages/threeicons.png")}
                      className="img"
                      height={42}
                    />
                  </div>
                </div>
                <p className="pt-2">
                  Ensure a smooth transition to advanced systems with our
                  seamless migration services, minimizing disruption.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SolutionsPage;
