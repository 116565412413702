import { Card } from "react-bootstrap";
import "./Technologies.css";

import { techItemsArray } from "./TechItems";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const Technologies = ({ click }) => {
  // let width = window.innerWidth;
  // let number;
  // if (width > 480) {
  //   number = 7;
  // } else {
  //   number = 8;
  // }
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const location = useLocation();
  const currentPath = location.pathname;
  useEffect(() => {
    console.log("currentPath", currentPath);
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [location.pathname]); // Empty dependency array ensures the effect runs only once after initial render
  // explore all technologies
  return (
    <>
      <div className=" d-flex flex-wrap mb-md-5 my-3 mt-md-0 tech-card-section b m-2">
        {click
          ? techItemsArray.map((data, i) => {
              return (
                <>
                  {/* {i < number ? ( */}
                  <div
                    className="col-md-4 col-6 my-2 px-xl-5 px-lg-3 px-1"
                    key={i}
                  >
                    {/* front card */}
                    <div className="tech-card">
                      <div className="unflipped flip">
                        <Card className=" border-radius p-2 ">
                          <Card.Img
                            loading="lazy"
                            alt="Card Img"
                            variant="top"
                            src={data.img}
                            // height={windowWidth > 2520 ? 500 :300}
                            height={300}
                          />
                        </Card>
                        <div className="card-text text-white col-10 ">
                          <div className="bg-white rounded shadow ">
                            <h6 className="unflipped title-heading p-md-3 pt-2 pb-2 cardtext">
                              {data.title}
                            </h6>
                          </div>
                        </div>
                      </div>
                      {/* flipped card */}
                      <div className="flipped-card d-none flip">
                        <Card className="border-radius">
                          <Card.Body>
                            <div className="inside-card mt-md-3">
                              <div className="icon mb-md-4 mb-2">
                                <img
                                  src={data.flippedImg}
                                  alt="Icon"
                                  width="120%"
                                />
                              </div>
                              <h5 className="flipped title-heading">{data.flippedTitle}</h5>
                              {/* <h5 className="font-customh6">{data.flippedTitle}</h5> */}
                              {/* <p >{data.flippedText}</p> */}
                              <p className=" ">{data.flippedText}</p>
                            </div>
                            {/* card bottom banner */}
                            <div className="card-flip-text  col-10 ">
                              <div className="bg-white rounded shadow ">
                                <h6 className="flipped title-heading p-md-3 pt-2 pb-2 ">
                                  {data.title}
                                </h6>
                              </div>
                            </div>
                            {/* {windowWidth < 2500 ? <div className="card-flip-text  col-10 ">
                                <div className="bg-white rounded shadow ">
                                  <h6 className="x title-heading p-md-3 pt-2 pb-2 ">
                                    {data.title}
                                  </h6>
                                </div>
                              </div> : <></>} */}
                          </Card.Body>
                        </Card>
                      </div>
                    </div>
                  </div>
                  {/* ) : (
                    <></>
                  )} */}
                </>
              );
            })
          : windowWidth > 480
          ? techItemsArray.map((data, i) => {
              return (
                <div
                  className="col-md-4 col-6 my-2 px-xl-5 px-lg-3 px-1 "
                  key={i}
                >
                  <div className="tech-card">
                    <div className="unflipped flip">
                      <Card className=" border-radius p-2 h-100 ">
                        <img
                          loading="lazy"
                          alt="Card Img"
                          variant="top"
                          className="card-unflip"
                          src={data.img}
                          height={300}
                        />
                      </Card>
                      {/* <div className="card-text shadow bg-white rounded col-10 ">
                        <h6 className="text-dark p-1 " >{data.title}</h6>
                      </div> */}
                      <div className="card-text text-white col-10 ">
                        <div className="bg-white rounded shadow text-center">
                          <h6 className="title-heading p-md-3 pt-2 pb-2 cardtext text-center">
                            {data.title}
                          </h6>
                        </div>
                      </div>
                    </div>

                    <div className="flipped-card d-none flip">
                      <Card className="border-radius ">
                        <Card.Body>
                          <div className="inside-card mt-md-3">
                            <div className="icon mb-md-4 mb-2">
                              <img
                                src={data.flippedImg}
                                alt="Icon"
                                width="120%"
                              />
                            </div>
                            <h5 className="flipped title-heading">{data.flippedTitle}</h5>
                            {/* <p>{data.flippedText}</p> */}
                            <p className="font-custom">{data.flippedText}</p>
                          </div>
                          <div className="card-flip-text  col-10 ">
                            <div className="bg-white rounded shadow ">
                              <h6 className="flipped text-dark title-heading p-md-3 pt-2 pb-2">
                                {data.title}
                              </h6>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                </div>
              );
            })
          : techItemsArray.map((data, i) => {
              return (
                <>
                  {i !== 6 && (
                    <div className="col-md-4 col-6 my-2 px-lg-5 px-1 " key={i}>
                      <div className="tech-card">
                        <div className="unflipped flip ">
                          <Card className=" border-radius p-2 ">
                            <Card.Img
                              loading="lazy"
                              alt="Card Img"
                              variant="top"
                              src={data.img}
                              height={300}
                              // height={windowWidth > 2520 ? 500 :300}
                            />
                          </Card>
                          <div className="card-text  col-10 ">
                            <div className="bg-white rounded shadow ">
                              <h6 className="text-dark title-heading p-md-3  pt-2 pb-2">
                                {data.title}
                              </h6>
                            </div>
                          </div>
                        </div>

                        <div className="flipped-card d-none flip ">
                          <Card className="border-radius">
                            <Card.Body>
                              <div className="inside-card mt-md-3">
                                <div className="icon mb-md-4 mb-2">
                                  <img
                                    src={data.flippedImg}
                                    alt="Icon"
                                    width="120%"
                                  />
                                </div>
                                <h5>{data.flippedTitle}</h5>
                                <p>{data.flippedText}</p>
                              </div>
                              <div className="card-flip-text  col-10 ">
                                <div className="bg-white rounded shadow ">
                                  <h6 className="text-dark title-heading p-md-3  pt-2 pb-2">
                                    {data.title}
                                  </h6>
                                </div>
                              </div>
                            </Card.Body>
                          </Card>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              );
            })}
      </div>
    </>
  );
};

export default Technologies;
