export const techItemsArray = [
  // {
  //   img: require("../../images/techImages/Software-Development.png"),
  //   title: "Custom Software Development",
  //   flippedImg: require("../../images/techImages/custom-soft-dev.png"),
  //   flippedTitle: "Custom Software Development",
  //   flippedText:
  //     "You get a customised software developed that helps your business run more efficiently and effectively.",
  // },
  {
    img: require("../../images/techImages/Web-Development.png"),
    title: "Web Development",
    flippedImg: require("../../images/techImages/web-dev.png"),
    flippedTitle: "Web Development",
    flippedText:
      "We provide you stunning web solutions curated especially for you that reflects your brand and meets your business goals.",
  },
  {
    img: require("../../images/techImages/Mobile-App-Development.png"),
    title: "Mobile App Development",
    flippedImg: require("../../images/techImages/mobile-dev.png"),
    flippedTitle: "Mobile App Development",
    flippedText:
      "Engage your customers with a beautifully designed mobile app providing smooth user experience for your business.",
  },

  // {
  //   img: require("../../images/techImages/SEO-Optimisation.png"),
  //   title: "SEO ",
  //   flippedImg: require("../../images/techImages/seo.png"),
  //   flippedTitle: "SEO ",
  //   flippedText:
  //     "Get your website noticed and increase traffic with our help in optimising your website for search engines.",
  // },
  {
    img: require("../../images/techImages/IOTimage.png"),
    title: "IOT",
    flippedImg: require("../../images/techImages/Iotlogo.png"),
    flippedTitle: "IOT ",
    flippedText:
      "Unlock the power of connected devices with our IoT solutions, enabling businesses to make informed decisions and optimize operations.",
  },

  {
    img: require("../../images/techImages/Blockchain.png"),
    title: "Blockchain",
    flippedImg: require("../../images/techImages/BlockChainlogo.png"),
    flippedTitle: "Blockchain ",
    flippedText:
      "Embrace the future of secure and transparent transactions with our blockchain expertise, streamlining processes and enhancing data integrity..",
  },

  {
    img: require("../../images/techImages/Software-Development.png"),
    title: "Custom Software Development",
    flippedImg: require("../../images/techImages/custom-soft-dev.png"),
    flippedTitle: "Custom Software Development",
    flippedText:
      "You get a customised software developed that helps your business run more efficiently and effectively.",
  },

  {
    img: require("../../images/digitalmarketing.png"),
    title: "Digital Marketing",
    flippedImg: require("../../images/techImages/digi-market.png"),
    flippedTitle: "Digital Marketing",
    flippedText:
      // "Upgrade your crafted software solutions to meet your specific business needs and drive growth.",
      "Supercharge your online presence with our integrated Digital Marketing solutions to drive qualified leads to your website and will help you achieve measurable success and maximize your ROI.",
  },

  {
    img: require("../../images/techImages/cloudsecurity.png"),
    title: "Cloud Security",
    flippedImg: require("../../images/techImages/cloud-sec.png"),
    flippedTitle: "Cloud Security",
    flippedText:
      "Security is essential in the internet world today. Unlock the benefits of the cloud to help you in migrating and maintaining your data.",
  },

  {
    img: require("../../images/uiux.png"),
    title: "UI/UX Design",
    flippedImg: require("../../images/techImages/ux-design.png"),
    flippedTitle: "UI/UX Design",
    flippedText:
      "Elevating user experiences through creating intuitive designs that captivate and engage your audience.",
  },
  {
    img: require("../../images/techImages/IT.png"),
    title: "IT Consultation",
    flippedImg: require("../../images/techImages/it-consult.png"),
    flippedTitle: "IT Consultation",
    flippedText:
      "Let us guide you in making the right technology investments to achieve your business goals. Sometimes all you need is to have a word with the right minds.",
  },

  // {
  //   img: require("../../images/techImages/QAtesting1.png"),
  //   title: "QA Testing",
  //   flippedImg: require("../../images/techImages/quality.png"),
  //   flippedTitle: "Testing Q/A",
  //   flippedText:
  //     "Get rigorous quality assurance to ensure flawless performance and reliability of your digital products.",
  // },

  // {
  //     img : require('../../images/techImages/SEO-Optimisation.png'),
  //     title : 'SEO Optimisation',
  //     flippedImg : require('../../images/techImages/seo-svg.png'),
  //     flippedTitle : 'SEO Optimisation',
  //     flippedText : 'Get your website noticed and increase traffic with our help in optimising your website for search engines.'
  // },
  //   {
  //     img: require("../../images/techImages/IT.png"),
  //     title: "IT Consultation",
  //     flippedImg: require("../../images/techImages/IT-svg.png"),
  //     flippedTitle: "IT Consultation",
  //     flippedText:
  //       "Let us guide you in making the right technology investments to achieve your business goals. Sometimes all you need is to have a word with the right minds.",
  //   },
];