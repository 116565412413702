import React from "react";
import "./ClientSpotLight.css";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Autoplay,
  Pagination,
  Scrollbar,
  A11y,
  FreeMode,
} from "swiper/modules";

import image1 from "../../images/homeImages/p2slogo.png";
import image2 from "../../images/homeImages/additivelogo.png";
import image3 from "../../images/homeImages/Izelogo.png";
import image4 from "../../images/homeImages/kashlogo.png";
import image5 from "../../images/homeImages/ignislogo.png";
import image6 from "../../images/homeImages/sanjolilogo1.png";
import image7 from "../../images/homeImages/FinexaCapital.png";
import image8 from "../../images/homeImages/chhavilogo.png";
import image9 from "../../images/homeImages/SSlogo.png";
import image10 from "../../images/homeImages/claaylogo.png";
import image11 from '../../images/homeImages/Aatmanlogo.png'

const ClientSpotLight = () => {
  const HighlightsItems = [
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
    image10,
    image11,
  ];

  return (
    <>
      <div className="home-highlights mt-md-5 pt-md-5 mt-5 pb-md-5">
        <div className="text-center">
          <h1>Client Spotlights</h1>
          <p className="text-secondary py-3 p-1 ">
            Showcasing our exceptional partnerships, inspiring growth and
            innovation.
          </p>
        </div>

        <hr />
        <Swiper
          modules={[
            Navigation,
            Autoplay,
            Pagination,
            Scrollbar,
            A11y,
            FreeMode,
          ]}
          // autoplay={true}
          spaceBetween={10}
          autoplay={{
            delay: 1000,
          }}
          className=" d-lg-block d-sm-block "
          breakpoints={{
            0: { slidesPerView: 2 },

            576: { slidesPerView: 4 },
          }}
          loop={true}
          speed={2500}
        >
          {HighlightsItems.map((data, i) => (
            <SwiperSlide className="text-center d-sm-block home-highlights " key={i}>
              <div className="mx-md-4 mx-2 ">
                <img
                  src={data}
                  alt="Company Logo"
                  className="img-fluid img-width "
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <hr />
      </div>
    </>
  );
};

export default ClientSpotLight;
