export const CurrentPositions = [
    {
        position : "Front End Developer - React Js",
        stats : "Develop and execute high-quality HTML/CSS code, ensuring the best user experience for our applications.",
        summary : "We are seeking a skilled and motivated Front End Developer to join our team. In this role, you will collaborate closely with our design team to create and implement high-quality code for front-end applications. Your expertise in React.js, HTML/5, CSS, SCSS, and JavaScript will be essential in delivering exceptional user experiences. As a member of our team, you will contribute to the development of software modules, design user-friendly interfaces, and ensure the seamless deployment of our applications. This is a full-time position ideal for both experienced professionals and fresher candidates.",
        responsibilities : [
            {
                point : "Collaborate closely with the design team to translate design concepts into effective front-end interfaces."
            },
            {
                point : "Develop and execute high-quality HTML/CSS code, ensuring the best user experience for our applications."
            },
            {
                point : "Create and implement codebase using technologies like React.js, HTML/5, CSS, and SCSS."
            },
            {
                point : "Design and develop software modules, create installers, and oversee deployment on hosting platforms."
            },
            {
                point : "Leverage your knowledge of static, dynamic, semantic, and accessible web development to enhance website functionality."
            },
            {
                point : "Utilize basic Git knowledge to ensure version control and efficient collaboration within the development team."
            },
            {
                point : "Apply your understanding of e-commerce principles to contribute to the development of online commercial projects."
            }
        ],
        qualifications : [
            {
                point : "Bachelor's degree in a relevant field is preferred."
            },
            {
                point : "1-3 years of experience in front-end development is preferred."
            },
            {
                point : "Proficiency in React.js is highly desirable."
            },
            {
                point : "Strong grasp of HTML/5, CSS, SCSS, and JavaScript."
            },
            {
                point : "Experience in building websites with static, dynamic, semantic, and accessible features."
            },
            {
                point : "Basic understanding of Git version control."
            },
            {
                point : "Familiarity with e-commerce concepts is a plus."
            }
        ],
        shortTxt : "If you are a creative thinker who enjoys working in a dynamic environment and wants to contribute to the development of innovative products, then we encourage you to apply for this exciting opportunity.",
        mode : "Full Time"
    }
]


// Template 
// {
//     position : "",
//     experience : "",
//     summary : "",
//     responsibilities : [
//         {
//             point : ""
//         },
//         {
//             point : ""
//         },
//         {
//             point : ""
//         },
//         {
//             point : ""
//         },
//         {
//             point : ""
//         },
//         {
//             point : ""
//         },
//     ],
//     qualifications : [
//         {
//             point : ""
//         },
//         {
//             point : ""
//         },
//         {
//             point : ""
//         },
//         {
//             point : ""
//         },
//         {
//             point : ""
//         },
//         {
//             point : ""
//         },
//     ],
//     shortTxt : "",
//     mode : ""
// }
