import React from "react";
import "./about.css";
import Founders from "./Founders";

export default function about() {
  const widthh = window.innerWidth;

  return (
    <>
      {/* Banner  */}
      <div className="solution-banner position-relative">
        <img
          src={require("../images/aboutUsImages/about-banner.png")}
          width="100%"
          alt="Banner"
          className="img-fluid"
        />
        <div className="banner-inner text-center text-white">
          <h1 className="mb-lg-4">About Us</h1>
          <p>
            Your Destination for Digital Transformation: Explore our tale of
            turning possibilities into realities with state-of-the-art IT
            solutions.
          </p>
        </div>
      </div>

      {/* Who we are  */}
      <div
        className={`business-success d-flex flex-wrap justify-content-between ${
          widthh < 800 && widthh > 700 ? "container" : ""
        }`}
      >
        <div className="col-md-5 align-self-end d-none d-md-block">
          <img
            src={require("../images/aboutUsImages/after-banner1.png")}
            alt="About Img"
            height="350"
            width="100%"
            className="img-fluid abt-exp-img "
          />
        </div>

        <div className="col-md-6 pe-md-5 px-2 ">
          <div className="text-md-end text-center pt-3 ">
            <h1 className="light-heading my-xl-4 my-lg-4 text-uppercase text-end">
              # Who WE ARE?
            </h1>
          </div>
          <div className="end-pad">
            <h1 className="my-xl-5 my-lg-4 my-3">
              Increasing Your Business{" "}
              <span className="primary-color">Success</span> With Technology
            </h1>
            <p className="my-xl-5 my-lg-4 my-3">
              A leading IT company dedicated to providing you a custom solution
              and service that empowers your business to achieve its goals by
              ensuring seamless integration, scalable growth, and unparalleled
              support every step of the way.
            </p>
            <h4 className="fw-light mb-lg-4 mb-2">Want to know more?</h4>
            <p className="mb-4 mb-md-0">
              Let us take you to a brief tour on what we do.
            </p>
          </div>
        </div>

        <div className="col-md-5 col-12 align-self-end d-block d-md-none">
          <img
            src={require("../images/aboutUsImages/after-banner.png")}
            alt="About Img"
            height="350"
            width="100%"
            className="img-fluid abt-exp-img"
          />
        </div>
      </div>

      {/* Founders */}
      {/* <Founders/> */}

      {/* What we do  */}
      <div className="bg-needs pb-5">
        <div className="container">
          <div className="text-md-end text-center pt-3">
            <h1 className="light-heading py-4 text-uppercase text-end">
              # WhAT WE DO?
            </h1>
          </div>
          <h1 className="pb-5 hand ">
            Handling All Your <span className="primary-color">IT Needs</span> At{" "}
            <br className="d-none d-md-block" /> One Place
          </h1>

          <div className="d-flex position-relative flex-wrap justify-content-around">
            <div className="col-lg-3 col-md-3 col-9 mx-auto need-pad py-2 py-md-0 pe-md-3 pe-lg-0 pe-0 ">
              <img
                src={require("../images/aboutUsImages/design.png")}
                alt="Needs Img"
                width="30%"
                className="img-fluid pad-img ms-2"
              />
              <h4 className="ms-2">DESIGN</h4>
              <p className="secondary-light-txt ms-2 ">
                We understand your problem and discuss all the possible solution
                you like, to give you the best in a one-to-one conference.
              </p>
            </div>
            <div className="col-lg-3 col-md-3 col-9 mx-auto need-pad py-2 py-md-0 pe-md-3 pe-lg-0 pe-0">
              <img
                src={require("../images/aboutUsImages/develop.png")}
                alt="Needs Img"
                width="30%"
                className="img-fluid pad-img ms-2"
              />
              <h4 className="ms-2">DEVELOP</h4>
              <p className="secondary-light-txt ms-2 ">
                The most interesting part where we ideate the direction to the
                best solution your business needs.
              </p>
            </div>
            <div className="col-lg-3 col-md-3 col-9 mx-auto need-pad py-2 py-md-0 pe-md-3 pe-lg-0 pe-0">
              <img
                src={require("../images/aboutUsImages/deploy.png")}
                alt="Needs Img"
                width="30%"
                className="img-fluid pad-img ms-2"
              />
              <h4 className="ms-2">DEPLOY</h4>
              <p className="secondary-light-txt ms-2">
                We are not done yet until we talk out all you have in mind for
                the future perspective and success of your business.
              </p>
            </div>
            <div className="col-lg-3 col-md-3 col-9 mx-auto need-pad py-2 py-md-0 pe-md-3 pe-lg-0 pe-0">
              <img
                src={require("../images/aboutUsImages/maintain8.png")}
                id="maintain"
                alt="Needs Img"
                width="30%"
                className="img-fluid pad-img ms-2"
              />
              <h4 className="ms-2">MAINTAIN</h4>
              <p className="secondary-light-txt ms-2">
                Rest easy, as we tend to nurturing and safeguarding your
                creations for enduring excellence.
              </p>
            </div>

            <hr class="position-absolute hrStyle d-none d-md-block" />
          </div>
        </div>
      </div>

      {/* Process  */}
      <div className="container process">
        <div className="text-md-end text-center pt-3">
          <h1 className="light-heading py-4 text-uppercase"># How, you Ask?</h1>
        </div>
        <h1 className="pb-5">
          Let's drive you through our <br />
          <span className="primary-color">Process</span>
        </h1>

        <div className="d-flex flex-wrap justify-content-around  align-items-end">
          <div className="col-lg-3 col-md-5 int-pad">
            <img
              src={require("../images/aboutUsImages/process-1.png")}
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="col-lg-7 col-md-5 ending-pad int-pad">
            <h3 className="secondary-light-txt mb-3">Intially,</h3>
            <p>
              We conduct in-depth research to develop a comprehensive
              understanding of your business needs and goals.
            </p>
            <p>
              We use our research to develop customized IT solutions that are
              tailored to your unique business requirements.
            </p>
          </div>

          <div className="col-lg-3 col-md-5 int-pad d-block d-md-none">
            <img
              src={require("../images/aboutUsImages/process-2.png")}
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="col-lg-7 col-md-5 starting-pad int-pad">
            <h3 className="secondary-light-txt mb-3">Followed by,</h3>
            <p>
              We provide a range of IT solutions to help you achieve your
              business goals. Our IT solutions are designed to streamline your
              business processes and improve efficiency.
            </p>
            <p>
              We offer a variety of IT solutions to meet your specific needs,
              including consulting, design, development, and support.
            </p>
          </div>
          <div className="col-lg-3 col-md-5 int-pad d-none d-md-block">
            <img
              src={require("../images/aboutUsImages/process-2.png")}
              alt=""
              className="img-fluid"
            />
          </div>

          <div className="col-lg-3 col-md-5 int-pad">
            <img
              src={require("../images/aboutUsImages/process-3.png")}
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="col-lg-7 col-md-5 ending-pad int-pad">
            <h3 className="secondary-light-txt mb-3">Oh wait! There's more.</h3>
            <p>
              We continuously optimize your IT solutions to ensure that they are
              functioning at their best.
            </p>
            <p>
              Our team is dedicated to improving the performance and
              effectiveness of your solution to help you achieve your business
              objectives.
            </p>
          </div>
        </div>
      </div>

      {/* Special us  */}
      <div className="bg-special py-md-5 pb-5">
        <div className="container pb-lg-3">
          <div className="text-md-end text-center pt-3">
            <h1 className="light-heading py-4 text-uppercase">
              # WHAT MAKES US SPECIAL
            </h1>
          </div>
          <h1 className="pb-5">
            It's <span className="primary-color">Our Philosophy</span> That{" "}
            <br /> Sets Us Apart
          </h1>

          <div className="col-xl-5 col-lg-8 col-md-10 col-11 bg-white mx-auto box-shodow px-5 py-3">
            <figure className="mb-0 position-relative">
              <blockquote className="blockquote mb-0">
                <p className="text-center mb-0">
                  What you build should change the world.
                </p>
              </blockquote>
            </figure>
          </div>
        </div>
      </div>
    </>
  );
}
