export const SolutionItemArray = [
  {
    img: require("../../images/solutionImages/enterprise.png"),
    title: "Enterprise Application",
    para: "Simplify intricate operations with our customized software solutions that cater to your business needs.",
  },
  {
    img: require("../../images/solutionImages/web-portal.png"),
    title: "Web Portals",
    para: "Create impactful online experiences with our interactive platforms, connecting users effortlessly.",
  },
  {
    img: require("../../images/solutionImages/e-commerce.png"),
    title: "E-Commerce",
    para: "Elevate your online presence and sales potential with our robust e-commerce solutions.",
  },
  {
    img: require("../../images/solutionImages/mobile-apps.png"),
    title: "Mobile Apps",
    para: "Enhance user engagement and accessibility with our innovative mobile applications.",
  },
  {
    img: require("../../images/solutionImages/ERP.png"),
    title: "ERP",
    para: "Integrate and optimize your business processes for increased efficiency and productivity.",
  },
  {
    img: require("../../images/solutionImages/CRM.png"),
    title: "CRM",
    para: "Strengthen customer relationships and streamline interactions with our efficient CRM solutions.",
  },
  {
    img: require("../../images/solutionImages/data-analytics.png"),
    title: "Data analytics",
    para: "Transform data into actionable insights for better decision-making and strategic planning.",
  },

  {
    img: require("../../images/solutionImages/CMS.png"),
    title: "CMS",
    para: "Manage content seamlessly and amplify your digital presence with our user-friendly CMS platforms.",
  },
];