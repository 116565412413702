import React, { useEffect, useState } from "react";
import "./Header.css";

import { Container, Nav, Navbar, Offcanvas } from "react-bootstrap";
import Logo from "../images/logo-light.png";
import LogoImg from "../images/Logo2.png";
import { Link,useLocation } from "react-router-dom";

export default function Header() {
//   const path = window.location.pathname;

  // const handleClose = () => {
  //     let close = document.getElementById('CloseFunction').classList
  //     let body = document.getElementsByName('body')
  //     close.add('collapsed')
  //     console.log(body);
  // }
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const location = useLocation()
  const currentPath = location.pathname;
  useEffect(() => {
    console.log("currentPath",currentPath)
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [location.pathname]); // Empty dependency array ensures the effect runs only once after initial render
  // explore all technologies
  return (
    <div className="header">
      {/* <div className='container'> */}
      <div
        className={`${
          windowWidth > 2519 ? "container-fluid-header" : "container"
        }`}
      >
        <Navbar expand="lg">
          <>
            {windowWidth > 2520 ? (
              <>
                <Link to="/" className="brand-logo py-2 navbar-brand">
                  <img
                    alt=""
                    src={LogoImg}
                    id="logo"
                    width="64px"
                    height="64px"
                    className="me-md-3 me-2 rounded-3 "
                  />
                  <img
                    id="datart"
                    src={Logo}
                    alt="Logo"
                    height="auto"
                    width="128px"
                  />
                </Link>
              </>
            ) : (
              <>
                <Link to="/" className="brand-logo py-2 navbar-brand">
                  <img
                    alt=""
                    src={LogoImg}
                    id="logo"
                    width="64px"
                    height="64px"
                    className="me-md-3 me-2 rounded-3 "
                  />
                  <img
                    id="datart"
                    src={Logo}
                    alt="Logo"
                    height="auto"
                    width="100%"
                  />
                </Link>
              </>
            )}
            {/* <Nav.Link href="/" className="brand-logo py-2 navbar-brand">
              <img
                alt=""
                src={LogoImg}
                id="logo"
                width="64px"
                height="64px"
                className="me-md-3 me-2 rounded-3 "
              />
              <img
                id="datart"
                src={Logo}
                alt="Logo"
                height="auto"
                width="100%"
                
              />
            </Nav.Link> */}
            <Navbar.Toggle
              aria-controls={`offcanvasNavbar-expand-lg`}
              id="CloseFunction"
            />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-lg`}
              aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
              placement="end"
            >
              <Offcanvas.Header closeButton></Offcanvas.Header>
              <Offcanvas.Body className="xyz">
                <Nav className="justify-content-end flex-grow-1 pe-4">
                  <Link
                    id="Home"
                    className={`${
                      currentPath === "/" ? "active " : ""
                    } nav-link secondary-txt me-3 `}
                    to="/"
                  >
                    Home
                  </Link>
                  <Link
                    id="Solutions"
                    className={`${
                      currentPath === "/solutions" ? "active" : ""
                    } nav-link secondary-txt me-3`}
                    to="/solutions"
                  >
                    Solutions
                  </Link>
                  <Link
                    id="About"
                    className={`${
                      currentPath === "/about-us" ? "active" : ""
                    } nav-link secondary-txt me-3`}
                    to="/about-us"
                  >
                    About Us
                  </Link>
                  <Link
                    id="Portfolio"
                    className={`${
                      currentPath === "/portfolio" ? "active" : ""
                    } nav-link secondary-txt me-3`}
                    to="/portfolio"
                  >
                    Portfolio
                  </Link>
                  <Link
                    id="Careers"
                    className={`${
                      currentPath === "/careers" ||
                      currentPath === "/careers/open-position"
                        ? "active"
                        : ""
                    } nav-link secondary-txt me-3`}
                    to="/careers"
                  >
                    Careers
                  </Link>
                  <Link
                    id="Contact"
                    className={`${
                      currentPath === "/contact-us" ? "active" : ""
                    } nav-link secondary-txt me-3`}
                    to="/contact-us"
                  >
                    Contact Us
                  </Link>
                </Nav>
              </Offcanvas.Body>

              {/* <Offcanvas.Body className='xyz'>
                                <Nav className="justify-content-end flex-grow-1 pe-4">
                                    <Link id='Home' className={`${path === '/' ? 'active' : ''} nav-link secondary-txt me-3`} onClick={()=>{handleClose();setPath('/')}} to="/">
                                        Home
                                    </Link>
                                    <Link id='Solutions' className={`${path === '/solutions' ? 'active' : ''} nav-link secondary-txt me-3`} onClick={()=>{handleClose();setPath('/solutions')}} to="/solutions">
                                        Solutions
                                    </Link>
                                    <Link id='About' className={`${path === '/about-us' ? 'active' : ''} nav-link secondary-txt me-3`} onClick={()=>{handleClose();setPath('/about-us')}} to='/about-us'>
                                        About Us
                                    </Link>
                                    <Link id='Portfolio' className={`${path === '/portfolio' ? 'active' : ''} nav-link secondary-txt me-3`} onClick={()=>{handleClose();setPath('/portfolio')}} to='/portfolio'>
                                        Portfolio
                                    </Link>
                                    <Link id='Careers' className={`${path === '/careers' || path === '/careers/open-position' ? 'active' : ''} nav-link secondary-txt me-3`} onClick={()=>{handleClose();setPath('/careers')}} to='/careers'>
                                        Careers
                                    </Link>
                                    <Link id='Contact' className={`${path === '/contact-us' ? 'active' : ''} nav-link secondary-txt me-3`} onClick={()=>{handleClose();setPath('/contact-us')}} to='/contact-us'>
                                        Contact Us
                                    </Link>
                                </Nav>
                            </Offcanvas.Body> */}
            </Navbar.Offcanvas>
          </>
        </Navbar>
      </div>
    </div>
  );
}
