import React, { useState, useEffect } from "react";
import "./home.css";
import guide from "../images/guide.png";
import articon from "../images/arcticons_security.png";
import electron from "../images/vector.png";
import circum from "../images/circum_server.png";
import arrow from "../images/ph_arrow-up-light.png";
import cloud from "../images/mdi-light_cloud.png";

import Technologies from "../components/Common/Technologies";
import Solutions from "../components/Common/Solutions";

import Banner from "../images/homeImages/home-bannerbig.png";
import BannerMob from "../images/homeImages/bannermob.png";
import TechDown from "../images/techImages/tech-down-img.png";

import Customer from "../components/Common/Customer";
import Testimonials from "../components/Common/Testimonials";
import ClientSpotLight from "../components/Common/ClientSpotLight";
import Stats from "../components/Common/Stats";

import Animation from "../components/Common/Animation";
import CssSwiper from "../components/Common/CssSwiper";
import { IoMdNavigate } from "react-icons/io";

export default function Home() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array ensures the effect runs only once after initial render
  // explore all technologies
  const [
    click,
    // eslint-disable-next-line
    setClick,
  ] = useState(true);

  // Explore all solutions
  const [
    solution,
    // eslint-disable-next-line
    setSolution,
  ] = useState(true);

  return (
    <>
      {/* Banner  */}
      <div className="banner">
        <img
          loading="eager"
          src={windowWidth > 480 ? Banner : BannerMob}
          // alt="Banner Img"
          // className="w-100 banner-img "
          width="100%"
          alt="Banner"
          className="banner-img"
        />
        <div className="banner-box">
          <div className="inner-box me-4">
            <h4 className="stats-color">Innovate. Integrate. Excel </h4>
            <h1 className="text-white textheading">
              Driving Excellence Through Customized Solutions
            </h1>

            {/* <p className="text-white mt-md-4  ">
              Innovative Solutions for a Modern Business World that powers your
              business success with technology.
            </p> */}
            <p className="text-white mt-md-3 mt-3 ">
              Let’s Embrace the Future of Success Together
            </p>
          </div>
        </div>
      </div>

      {/* Technologies  */}
      <div className="uncover-expertise">
        <div className="container  tech">
          <div className="col-12 tech-heading text-center">
            <div className="pt-5 pt-md-0">
              <h1 className="pt-5 ">Uncover Our Expertise</h1>
            </div>
            <p className="secondary-light-color mb-lg-5 mb-3">
              Your IT Journey Starts Here: Uncover Expert Solutions Crafted to
              Elevate Your Business Growth. Experience Customized Services for
              Your Success Story.
            </p>
          </div>
          {/* <div
            className={`${
              windowWidth > 2520 ? "container-fluid" : "container"
            }`}
          ></div> */}
          <Technologies />
          {/* <Technologies click={click} /> */}
          <div className={`text-center ${click ? "" : "d-none"}`}>
            {/* <img
              loading="lazy"
              src={TechDown}
              alt=""
              className={`sec-main-img`}
            /> */}
            {/* <a
              className="btn btn-primary rounded-5 px-5 py-2 mt-md-3 text-center"
              href="/solutions"
            >
              Explore All
            </a> */}
          </div>
        </div>
      </div>

      {/* Solutions  */}
      <div className="container-fluid mt-md-3 mt-3 pt-4 solution">
        <div className="col-12 solution-heading text-center">
          <h1 className="my-3">Solutions We Deliver</h1>
          <p className="secondary-light-color mb-lg-5">
            Effortlessly manage your cloud and on-premises systems with our
            comprehensive support for infrastructure and applications.
          </p>
        </div>
        <Solutions solution={solution} />
        <div className={`text-center pt-4 ${solution ? "" : "d-none"}`}>
          <a
            className="btn btn-secondary rounded-5 px-md-5 px-3 py-2 text-center"
            href="/solutions"
          >
            Explore all Solutions
          </a>
        </div>
      </div>

      {/* Stats  */}
      {/* <div className="container mt-5 stats">
                <div className="text-center">
                    <img loading='lazy' src={require('../images/stats-icon.png')} width='4%' alt="Stats Img" className="img-fluid" />
                    <h1 className='my-3'>Stats headline</h1>
                    <p className="secondary-light-color pb-3">Everything you need to convert, engage, and retain more users.</p>
                </div>
                <div className="d-flex flex-wrap justify-content-center">
                    <div className="col-lg-2 col-4 text-center">
                        <p className="big-font primary-txt mb-0">40+</p>
                        <p>Projects</p>
                    </div>
                    <div className="col-lg-2 col-4 text-center border-style-left">
                        <p className="big-font primary-txt mb-0">20+</p>
                        <p>Happy Clients</p>
                    </div>
                    <div className="col-lg-2 col-4 text-center border-style-left">
                        <p className="big-font primary-txt mb-0">4k+</p>
                        <p>Global customers</p>
                    </div>
                </div>
            </div> */}

      {/* _____________________________________________ */}

      {/* ClientSpotLight */}

      <Stats />

      <CssSwiper />

      {/* Ecosystem  */}
      {/* <div className="bg-ecosystem mt-5">
        <div className="container d-flex flex-wrap align-items-center eco-box">
          <div className="col-md-4 ps-lg-5 px-md-0 px-4">
            <h1 className=" mb-md-4">Your IT Ecosystem, Perfectly Managed</h1>
            <p className="mb-0 secondary-light-color">
              Effortlessly manage your cloud and on-premises systems with our
              comprehensive support for infrastructure and applications.
            </p>
          </div>
          <div className="col-md-8">
            <div className="row d-flex flex-wrap justify-content-center">
              <div className="fold-paddd col-md-6 col-12 px-lg-4 pe-md-0 ps-md-5 px-5 py-5 pt-md-0">
                <div className="box-style px-3">
                  <div className="d-flex justify-content-between">
                    <h4 className="pt-4 mb-3">Consulting</h4>
                    <img  alt="" src={guide} width={48} height={48} className="mt-3 me-3 air-styl" />
                  </div>
                  <p className="mb-0 secondary-light-color mt-2">
                    Leverage our expert guidance to optimize your IT strategies
                    and stay ahead of the curve.
                  </p>
                </div>
              </div>
              <div className="fold-paddd col-md-6 col-12 px-lg-4 pe-md-0 ps-md-5 px-5 pb-5">
                <div className="box-style px-3">
                  <div className="d-flex justify-content-between ">
                    <h4 className="pt-4 mb-3">Security</h4>
                    <img
                      alt=""
                      src={articon}
                      width={48}
                      height={48}
                      className="mt-3 me-3 air-styl"
                    />
                  </div>
                  <p className="mb-0 secondary-light-color mt-2">
                    Strengthen your digital assets with our robust security
                    solutions, safeguarding your business from potential
                    threats.
                  </p>
                </div>
              </div>
              <div className="fold-paddd col-md-6 col-12 px-lg-4 pe-md-0 ps-md-5 px-5 pb-5 pb-md-0 ">
                <div className="box-style px-3">
                  <div className="d-flex justify-content-between">
                    <h4 className="pt-4 mb-3">Implementation</h4>
                    <img  alt=""
                      src={electron}
                      width={48}
                      height={48}
                      className="mt-3 me-3 air-styl"
                    />
                  </div>
                  <p className="mb-0 secondary-light-color mt-2">
                    Seamlessly integrate new technologies into your operations,
                    enhancing efficiency and productivity
                  </p>
                </div>
              </div>
              <div className="fold-paddd col-md-6 col-12 px-lg-4 pe-md-0 ps-md-5 px-5 ">
                <div className="box-style px-3">
                  <div className="d-flex justify-content-between">
                    <h4 className="pt-4 mb-3">Migration</h4>
                    <div className="d-flex justify-content-between mt-2 me-3">
                      <img  alt="" src={circum} width={32} height={32} className="mt-3 me-1 air-styl" />
                      <img  alt="" src={arrow} width={12} height={12} className="mt-4 me-1 air-styl" />
                      <img  alt="" src={cloud} width={32} height={32} className="mt-3 air-styl" />
                    </div>
                  </div>
                  <p className="mb-0 secondary-light-color mt-2">
                    Ensure a smooth transition to advanced systems with our
                    seamless migration services, minimizing disruption and
                    downtime.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* why choose us  */}
      {/* <div className="d-flex flex-wrap align-items-center choose-us">
        <div className="col-md-6 px-0 text-center position-relative choose">
          <img
            loading="lazy"
            src={require("../images/choose-us-1.png")}
            alt=""
            className="img-fluid w-100"
          />
           <div className="position-absolute  chose-txt  col-md-7">
            <h1>Why Choose Us?</h1>
            <p className="col-md-9 px-2 mt-3 mx-5 ">
              Elevate your business with our unmatched IT solutions, crafted to
              empower your growth and achieve remarkable success.
            </p>
          </div>
        </div>
        <div className="col-md-6 px-1">
          <img
            loading="lazy"
            src={require("../images/homeImages/rectangle1.png")}
            alt=""
            className="img-fluid w-100"
          />
        </div>
      </div> */}

      <div className="d-flex  flex-wrap align-items-center bgdiv">
        <div className="col-md-6 order-mobile-2 pb-md-0 pb-4 ">
          {/* <div className="mx-4 mt-3">
            <h1 className="mx-md-4 mx-4">Why Choose Us?</h1>
            <p className="col-md-9 px-2 mt-3 mx-3 text-muted ">
              Elevate your business with our unmatched IT solutions, crafted to
              empower your growth and achieve remarkable success.
            </p>
          </div>
          <div className="">
          <div className="d-flex mt-md-5 mt-3 mx-md-5 mx-5  ">
            <div className="col-md-1 bg-info p-1 rounded-start-2">
              <IoMdNavigate
                className="navarrow mx-md-3"
                fill="white"
                size={20}
              />{" "}
            </div>
            <div className="bg-white  col-md-6 rounded-end-2 p-2 col-12 ">
              <p className="text-center mx-2 ">Customer-Centric Approach</p>
            </div>
          </div>

          <div className="d-flex  mt-md-3  mt-3 mx-md-5 mx-5">
            <div className="col-md-1 bg-info p-1 rounded-start-2">
              <IoMdNavigate
                className="navarrow mx-md-3"
                fill="white"
                size={20}
              />{" "}
            </div>
            <div className="bg-white col-md-6 rounded-end-2 p-2 col-12">
              <p className="text-center mx-2 ">High Quality Work</p>
            </div>
          </div>

          <div className="d-flex  mt-md-3 mt-3 mx-md-5 mx-5  ">
            <div className="col-md-1 bg-info p-1 rounded-start-2">
              <IoMdNavigate
                className="navarrow mx-md-3"
                fill="white"
                size={20}
              />{" "}
            </div>
            <div className="bg-white  col-md-6 rounded-end-2 p-2 col-12">
              <p className="text-center mx-2 ">Ethical & Professional</p>
            </div>
          </div>
          </div> */}
          <img src={require("../images/homeImages/chooseusimage.png")} className="img-fluid w-100 p-md-0 p-2" />
        </div>
        <div className="col-md-6 px-1 mt-md-0 mt-4  order-mobile-1">
          <img
            loading="lazy"
            src={require("../images/homeImages/Rectangle11.png")}
            alt=""
            className="img-fluid w-100 p-md-0 p-2"
          />
        </div>
      </div>

      {/* after choose  */}
      {/* <div className="after-choose position-relative">
        <img
          loading="lazy"
          src={require("../images/after-choose-bg.png")}
          alt="Back img"
          className="img-fluid w-100 d-none d-lg-block"
          style={windowWidth > 1880 ? { height: "500px" } : { height: "100%" }}
        />
        <div className="inside-data">
          <div className="container-fluid d-flex flex-wrap justify-content-center align-items-center">
            <div className="col-md-4 approches">
              <h3 className="mb-4">01</h3>
              <h4 className="mb-3">Customer-Centric Approach</h4>
              <p className="secondary-light-color">
                Putting our customer's needs first to create tailored solutions
                for their success.
              </p>
            </div>
            <div className="col-md-4 approches">
              <h3 className="mb-4">02</h3>
              <h4 className="mb-3">High Quality Work</h4>
              <p className="secondary-light-color">
                Delivering excellence through meticulous attention to detail and
                industry-leading standards.
              </p>
            </div>
            <div className="col-md-4 approches">
              <h3 className="mb-4">03</h3>
              <h4 className="mb-3">Ethical & Professional</h4>
              <p className="secondary-light-color">
                Upholding integrity and professionalism in all our interactions,
                building trust and long-lasting partnerships.
              </p>
            </div>
          </div>
        </div>
      </div> */}

      {/* stats */}
      {/* <div
        className={`${windowWidth > 2520 ? "container-fluid" : "container"}`}
      > */}
      {/* <Stats /> */}
      {/* </div> */}

      {/* Success stories */}
      <div className="back-success">
        <div className="text-center">
          <p className="pt-5 secondary-light-color">Our Projects</p>
          <h1 className=" mt-3 m-0 p-0">Our Success Stories</h1>
        </div>

        {/* <Customer /> */}
        <Animation />
      </div>

      {/* Testimonials-new */}

      {/* <Clients /> */}

      {/* Testimonials */}

      <div
        className={`${
          windowWidth > 2520 ? "container-fluid" : "container"
        }   mb-5`}
      >
        <div className="text-center">
          <p className="mt-5 secondary-light-color">Testimonials</p>
          <h1 className="mb-5 mt-3">Client Voices</h1>
        </div>

        <Testimonials />
      </div>
    </>
  );
}
